// Core
import { useContext, useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';

// Context
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { Label } from 'components/Labels';
import { InfoButton } from 'components/Buttons';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {
	Input,
	SelectCustom,
	TextFieldInput,
} from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';

// Styles
import s from '../MuiMultipleCheckedOutlets/MuiMultipleCheckedOutlets.module.css';

const MuiMultipleCheckedCountries = ({
	handleCheckedCountries,
	accountId,
	lang,
	outlets,
	tracksIds,
	dateFrom,
	dateTo,
	selectedFromPage,
	chartMode,
	isAdmin,
}) => {
	const [countries, setCountries] = useState([]);
	const [selectedData, setSelectedData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [filteredItems, setFilteredItems] = useState([]);
	const [isBtnOnlyShowId, setIsBtnOnlyShowId] = useState('');
	const [isLoaded, setIsLoaded] = useState(true);

	const {
		getAccountStatisticsCountries,
		getAccountStatisticsAuthorCountries,
	} = useContext(RootContext);
	const { showModal } = useContext(UIContext);

	const ref = useRef(false);

	const countriesRequest =
		chartMode === 'compo'
			? getAccountStatisticsAuthorCountries
			: getAccountStatisticsCountries;

	useEffect(() => {
		if (selectedFromPage) {
			setSelectedData([selectedFromPage]);
			setCountries(
				countries.map((country) => {
					return {
						...country,
						checked: country.code === selectedFromPage.code,
					};
				})
			);
		}
	}, [selectedFromPage]);

	const getCountries = () => {
		const mappedOutlets = outlets.map((item) => item.code);
		countriesRequest(
			isAdmin,
			accountId,
			dateFrom,
			dateTo,
			mappedOutlets,
			tracksIds,
			lang
		).then((res) => {
			res = res.data.data.global;
			setIsLoaded(false);
			let mappedCountries = Object.keys(res).map((item) => {
				return {
					...res[item],
					code: item,
					checked: true,
				};
			});
			setCountries([{ raw_title: 'All', checked: true }, ...mappedCountries]);
		});
	};

	useEffect(() => {
		setIsLoaded(true);
		getCountries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateTo, dateFrom, outlets, tracksIds, chartMode]);

	useEffect(() => {
		if (countries.length) {
			const data = countries.filter((item) => item.checked && item.code);
			setSelectedData(data);
			const isAllChecked = data.length === countries.length - 1;
			handleCheckedCountries(data, isAllChecked);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries]);

	useEffect(() => {
		if (selectedData.length > 0) {
			ref.current = true;
		}
	}, [selectedData]);

	useEffect(() => {
		if (searchValue.trim().length) {
			const filteredCountries = countries
				.filter(
					(country) =>
						!country.code ||
						country[`title_${lang}`]
							?.toLocaleLowerCase()
							.includes(searchValue?.toLocaleLowerCase())
				)
				.map((item) => {
					const isInFilterItems = filteredItems.find(
						(filteredItem) => filteredItem.code === item.code
					);

					return {
						...item,
						checked: isInFilterItems ? isInFilterItems.checked : false,
					};
				});
			setFilteredItems(filteredCountries);
		}
	}, [searchValue]);

	const handleChecked = (country) => {
		let data = countries;
		if (country.raw_title === 'All') {
			country.checked ? (country.checked = false) : (country.checked = true);
			data = data.map((item) => {
				if (searchValue) {
					const isInFilterItems = filteredItems.find(
						(filteredItem) => filteredItem.code === item.code
					);
					item.checked = !country.checked
						? false
						: isInFilterItems
						? country.checked
						: !country.checked;
					return item;
				}
				item.checked = country.checked;
				return item;
			});
		} else {
			data = data.map((item) => {
				if (item.raw_title === 'All') item.checked = false;
				if (item.code === country.code) {
					item.checked ? (item.checked = false) : (item.checked = true);
				}
				return item;
			});
		}

		if (searchValue.trim().length) {
			const filteredData = [
				...data.filter(
					(country) =>
						!country.code ||
						country[`title_${lang}`]
							?.toLocaleLowerCase()
							.includes(searchValue?.toLocaleLowerCase())
				),
			];
			setFilteredItems(filteredData);
			setSelectedData(
				filteredData.filter((country) => country.checked && country.code)
			);
			setCountries(data);

			return;
		}
		setCountries(data);
		setSelectedData(data.filter((country) => country.checked && country.code));
	};

	const getTitle = (country) => {
		if (country.raw_title && country.raw_title === 'All')
			return <FormattedMessage id={'rod.statistic.search-all'} />;

		if (country.code === 'ZZ') {
			return (
				<InfoButton
					className={s.help_btn}
					onClick={showModal({
						text: (
							<FormattedMessage
								id={'rod.statistic.unidentified_country.help'}
							/>
						),
					})}
				>
					<Label>{country[`title_${lang}`]}</Label>
				</InfoButton>
			);
		}

		return country[`title_${lang}`];
	};

	const handleChange = (e) => {
		const {
			target: { value },
		} = e;
		setSearchValue(value);
	};

	const handleClickOnly = (country) => {
		const selectedCountries = countries.map((existCountry) => {
			if (existCountry.code === country.code) {
				return {
					...existCountry,
					checked: true,
				};
			}
			return {
				...existCountry,
				checked: false,
			};
		});
		setCountries(selectedCountries);
		setSelectedData([country]);
	};

	const handleMouseOver = (index) => {
		if (index) {
			setIsBtnOnlyShowId(index);
		}
	};

	const handleMouseLeave = () => {
		setIsBtnOnlyShowId('');
	};

	const renderMenuItem = (country, index) => {
		return (
			<MenuItem
				className={s.menuItem}
				key={index}
				onMouseOver={() => handleMouseOver(index)}
				onMouseLeave={handleMouseLeave}
				value={country.raw_title}
			>
				<CheckboxBlack
					checked={country.checked}
					onChange={() => handleChecked(country)}
				/>
				<Label>{getTitle(country)}</Label>
				{isBtnOnlyShowId && isBtnOnlyShowId === index && country.code !== 'ZZ' && (
					<button
						className={s.btnOnly}
						style={{ cursor: 'pointer' }}
						onClick={() => handleClickOnly(country)}
					>
						<FormattedMessage id={'rod.statistic.only'} />
					</button>
				)}
				{isBtnOnlyShowId && isBtnOnlyShowId === index && country.code === 'ZZ' && (
					<button
						className={s.btnOnly}
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							background: 'var(--color-black)',
							color: 'var(--color-white)',
							font: 'var(--gilroy-Medium-14-17)',
							border: 'none',
							cursor: 'pointer',
							padding: '10px 8px',
						}}
						onClick={() => handleClickOnly(country)}
					>
						<FormattedMessage id={'rod.statistic.only'} />
					</button>
				)}
			</MenuItem>
		);
	};

	const handleResetFilter = () => {
		getCountries();
	};

	return (
		<FormControl
			variant="standard"
			sx={{ m: 1 }}
			className={s.formControl}
			error={ref.current}
		>
			<Input id="demo-multiple-checkbox-label">
				<FormattedMessage id={'rod.statistic.countries'} />
			</Input>
			<SelectCustom
				labelId="demo-multiple-checkbox-label"
				id="demo-multiple-checkbox"
				multiple
				onClose={() => setSearchValue('')}
				disabled={isLoaded}
				MenuProps={{
					style: { zIndex: 2147483601 },
				}}
				value={selectedData}
				renderValue={(selected) => {
					if (selected.length === 1) {
						return <>{selected[0][`title_${lang}`]}</>;
					}
					return selected.length <= countries.length - 1 ? (
						<>
							<FormattedMessage id={'rod.statistic.tracks-selected'} />
							:&nbsp;
							{selected.length}/{countries.length - 1}&nbsp;
							<FormattedMessage id={'rod.statistic.countries'} />
						</>
					) : (
						<>
							<FormattedMessage id={'rod.statistic.tracks-selected'} />
							:&nbsp;
							{selected.length - 1}/{countries.length - 1}&nbsp;
							<FormattedMessage id={'rod.statistic.countries'} />
						</>
					);
				}}
				className={s.selectCustom}
			>
				<FormattedMessage id={'rod.statistic.search-country'}>
					{(label) => (
						<TextFieldInput
							className={s.search}
							autoFocus={false}
							initialValue={searchValue ? searchValue : ''}
							onChange={handleChange}
							label={label}
							variant="standard"
						/>
					)}
				</FormattedMessage>
				<div className={s.wrapper}>
					{searchValue
						? filteredItems.map(renderMenuItem)
						: countries.map(renderMenuItem)}
				</div>
			</SelectCustom>
			<button className={s.buttonResetFilter} onClick={handleResetFilter}>
				<FormattedMessage id={'rod.statistic.reset-filter'} />
			</button>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(MuiMultipleCheckedCountries);
