// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import CloseIcon from '@mui/icons-material/Close';
import MultipleSelectCheckmarks from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedTracks';
import MultipleSelectCheckmarksNew from 'material-design/components/MuiMultipleCheckedTracksNew/MuiMultipleCheckedTracksNew';
import MultipleSelectOutletsCheckmarks from 'material-design/components/MuiMultipleCheckedOutlets/MuiMultipleCheckedOutlets';
import { Button } from 'components/Buttons';
import MuiMultipleCheckedCountries from 'material-design/components/MuiMultipleCheckedCountries/MuiMultipleCheckedCountries';

// Styles
import styles from './StatisticFilterModal.module.css';
import { isEqual } from 'lodash';

const StatisticFilterModal = ({
	active,
	setActive,
	statisticData,
	isAdmin,
	lang,
	accountId,
	errors,
	setLoading,
	startEndDates,
	checkedTopTrack,
	setInitialData,
	handleSetFilters,
	handleClearAllFilters,
	chartMode,
}) => {
	const [filtersData, setFiltersData] = useState({
		tracksIds: [],
		outlets: [],
		countries: [],
	});

	const [isInitial, setIsInitial] = useState(false);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [tracks, setTracks] = useState([]);
	const [outlets, setOutlets] = useState([]);
	const [trackSearchValue, setTrackSearchValue] = useState('');
	const [countries, setCountries] = useState([]);
	const [updatekey, setUpdateKey] = useState(Math.random() * 100);
	const [countryFromPage, setCountryFromPage] = useState(null);
	const [checkedAllCountries, setAllCheckedCountries] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [noOutlets, setNoOutlets] = useState(false);
	const [noTracks, setNoTracks] = useState(false);
	const [noCountries, setNoCountries] = useState(false);

	useEffect(() => {
		if (active) {
			document.body.style.position = 'fixed';
			document.body.style.top = `-${window.scrollY}px`;
		}
		if (!active) {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}, [active]);

	useEffect(() => {
		if (isInitial && tracks.length && outlets.length && countries.length) {
			setIsInitial(false);
			setInitialData(tracks, outlets, countries);
		}
	}, [isInitial, tracks, outlets, countries]);

	useEffect(() => {
		if (!isInitial && statisticData.countries.length === 1) {
			setCountryFromPage(statisticData.countries[0]);
		}
	}, [statisticData]);

	useEffect(() => {
		if (
			noOutlets ||
			noTracks ||
			noCountries ||
			!tracks.length ||
			!countries.length
		) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [noCountries, noTracks, noOutlets, tracks, countries]);

	const handleApplyAllFilters = () => {
		const compareObj = {
			tracksIds: statisticData.tracksIds,
			outlets: statisticData.outlets,
			countries: statisticData.countries,
		};

		if (!isEqual(compareObj, filtersData) || setAllCheckedCountries) {
			const sendItem = {};
			Object.keys(filtersData).forEach((filterKey) => {
				if (
					filterKey === 'countries' &&
					!filtersData[filterKey].length &&
					checkedAllCountries
				) {
					sendItem[filterKey] = filtersData[filterKey];
				}
				if (filtersData[filterKey].length) {
					sendItem[filterKey] = filtersData[filterKey];
				}
			});
			handleSetFilters(sendItem, isAllChecked, trackSearchValue);
		}
		setAllCheckedCountries(false);
		setActive(false);
	};

	const handleCheckedOutlets = (outlets) => {
		if (isInitial) {
			setOutlets(outlets);
			return;
		}

		filtersData.outlets = outlets;
		setFiltersData({ ...filtersData });

		if (!filtersData.outlets.length) {
			setDisabled(true);
			setNoOutlets(true);
		} else {
			setNoOutlets(false);
		}
	};

	const handleCheckedTracks = (ids, isAllChecked) => {
		setIsAllChecked(isAllChecked);

		if (isInitial) {
			setTracks(ids);
			return;
		}

		filtersData.tracksIds = isAllChecked ? [] : ids;
		setFiltersData({ ...filtersData });

		if (!ids.length) {
			setDisabled(true);
			setNoTracks(true);
		} else {
			setNoTracks(false);
		}
	};

	const handleCheckedCountry = (checkedCountries, isAllChecked) => {
		if (checkedCountries || isAllChecked) {
			setNoCountries(false);
		}

		if (!checkedCountries) {
			return;
		}

		if (isInitial) {
			setCountries(checkedCountries);
			return;
		}

		setCountryFromPage(null);
		filtersData.countries = isAllChecked ? [] : checkedCountries;

		if (isAllChecked) {
			setAllCheckedCountries(true);
		}

		setFiltersData({ ...filtersData });

		if (!isAllChecked && !filtersData.countries.length) {
			setDisabled(true);
			setNoCountries(true);
		} else {
			setNoCountries(false);
		}
	};

	const handleClearAll = () => {
		setFiltersData({
			tracksIds: [],
			outlets: [],
			countries: [],
		});
		setUpdateKey(Math.random() * 100);
		handleClearAllFilters();
		setActive(false);
	};

	const handleCheckedTopTrack = () => {};

	useEffect(() => {
		setIsInitial(true);
		setLoading(true);
		setDisabled(false);
		setNoTracks(false);
	}, []);

	return (
		<>
			<div
				className={
					active ? `${styles.Overlay} ${styles.OverlayActive}` : styles.Overlay
				}
			/>
			<div
				className={active ? `${styles.modal} ${styles.active}` : styles.modal}
				onClick={() => setActive(false)}
			>
				<div
					key={updatekey}
					className={styles.container}
					onClick={(e) => e.stopPropagation()}
				>
					<div className={styles.modalHeader}>
						<div className={styles.modalHeader__titleWrapper}>
							<span className={styles.modalHeader__title}>
								<FormattedMessage id={'rod.release.info.filter'} />
							</span>
							<span
								onClick={handleClearAll}
								className={styles.modalHeader__clear}
							>
								<FormattedMessage id={'rod.repertoire.clear_all'} />
							</span>
							<div className={styles.modalHeader__icon}>
								<CloseIcon
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					<div className={styles.modalBody}>
						<div className={styles.setting}>
							<div className={styles.inputOutlets}>
								<MultipleSelectOutletsCheckmarks
									handleCheckedOutlets={handleCheckedOutlets}
									lang={lang}
									className={styles.tracksCheckmarks}
									chartMode={chartMode}
									buttonReset={true}
									zIndex
								/>
								{Object.keys(errors).includes('outlets') && (
									<span className={styles.Helper}>error</span>
								)}
								{noOutlets && (
									<span className={styles.error}>
										<FormattedMessage
											id={'rod.release.info.filters.modal.outlet.error'}
										/>
									</span>
								)}
							</div>
							<div className={styles.inputTrack}>
								<MultipleSelectCheckmarksNew
									isAdmin={isAdmin}
									accountId={accountId}
									handleCheckedTracks={handleCheckedTracks}
									checkedTopTrack={checkedTopTrack}
									handleCheckedTopTrack={handleCheckedTopTrack}
									className={styles.tracksCheckmarks}
									dateFrom={startEndDates[0]}
									dateTo={startEndDates[1]}
									setLoading={setLoading}
									setTrackSearchValue={setTrackSearchValue}
									chartMode={chartMode}
									buttonReset={true}
								/>
								{Object.keys(errors).includes('tracks') && (
									<span className={styles.Helper}>error</span>
								)}
								{noTracks && (
									<span className={styles.error}>
										<FormattedMessage
											id={'rod.release.info.filters.modal.track.error'}
										/>
									</span>
								)}
							</div>
							<div className={styles.inputOutlets}>
								<MuiMultipleCheckedCountries
									accountId={accountId}
									handleCheckedCountries={handleCheckedCountry}
									dateFrom={startEndDates[0]}
									dateTo={startEndDates[1]}
									tracksIds={filtersData.tracksIds}
									outlets={filtersData.outlets}
									lang={lang}
									selectedFromPage={countryFromPage}
									className={styles.tracksCheckmarks}
									chartMode={chartMode}
									isAdmin={isAdmin}
								/>
								{noCountries && (
									<span className={styles.error}>
										<FormattedMessage
											id={'rod.release.info.filters.modal.country.error'}
										/>
									</span>
								)}
							</div>
						</div>
						<Button
							className={styles.submit_button}
							variant="primary"
							onClick={() => handleApplyAllFilters()}
							disabled={disabled}
						>
							<FormattedMessage id={'rod.release.info.apply'} />
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default StatisticFilterModal;
