// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import lscache from 'lscache';
import moment from 'moment';
import { format } from 'date-fns';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { BottomNavi } from 'components';
import ModalWarning from './ModalWarning/ModalWarning';
import TracksForm from './TracksForm/TracksForm';
import Sidebar from './Sidebar/Sidebar';
import { SidepageModal } from 'material-design/modals';
import { ArtistForm } from 'material-design/ReleaseCreate/ArtistForm';

// Utils
import { compose } from 'recompose';
import { withUI, withRoot, withAuth } from 'hocs';
import { setCache } from 'services/cacheHelper/setCache';
import { correctDateInTracks } from './utils/correctDateInTracks';
import { isDone } from './utils/isDone';
import { formatPerformers } from './utils/formatPerformers';
import { getFeaturedPerformers } from './utils/getFeaturedPerformers';
import { toGeneralFormat } from '../utils/toGeneralFormat';
import { getCreatedDateString, reachDataLayer, reachGoal } from 'utils';
import {
	accId,
	errorName,
	EVENTS,
	reachParams,
	sectionTitle,
	sourceName,
	userId,
} from 'utils/reachParams';

// Services
import { accounts, dictionaries } from 'services';

// Constants
import ModalTypes from 'constants/modalTypes';

// Styles
import s from './Tracks.module.css';

const Tracks = (props) => {
	const history = useHistory();
	const { accountId, user } = useContext(AuthContext);
	const { setIsBottomNaviShow, showModal } = useContext(UIContext);

	const idTypesHasSnippet = ['4', '42', '43', '63', '66', '70'];
	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;

	const [initialPersonal, setInitialPersonal] = useState([]);
	const [personal, setPersonal] = useState([]);
	const [releaseData, setReleaseData] = useState();
	const [selectPerson, setSelectPerson] = useState({});
	const [additionalReleases, setAdditionalReleases] = useState([]);
	const [errors, setErrors] = useState({});
	const [transferRelease, setTransferRelease] = useState(false);
	const [toogleMenu, setToogleMenu] = useState('');
	const [takeByLastPerson, setTakeByLastPerson] = useState(false);
	const [lastSavePerson, setLastSavePerson] = useState({});
	const [audio] = useState(new Audio());
	const [isReqLive] = useState(false);
	const [languages] = useState([]);
	const [countries, setCountries] = useState([]);
	const [isSnippedRender, setIsSnippedRender] = useState(false);
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [disabledNextBtn, setDisabledNextBtn] = useState(true);
	const [disableBtn, setDisableBtn] = useState(false);
	const [disableSaveBtn, setDisableSaveBtn] = useState(false);
	const [selectedPerformer, setSelectedPerformer] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [isModalActive, setIsModalActive] = useState(false);
	const [artistType, setArtistType] = useState('main_performer');
	const [unsavedChangesModalShowed, setUnsavedChangesModalShowed] = useState(
		false
	);
	const [releaseStepPerformers, setReleaseStepPerformers] = useState([]);

	const removeEmptyNameObjects = (arr) => {
		return arr.filter(
			(item) => !(item && typeof item === 'object' && item.name === '')
		);
	};

	const handleForm = async (index) => {
		toogleMenu === index ? setToogleMenu('') : setToogleMenu(index);

		try {
			const res = await accounts.getReleaseData(
				h11EditId && isH11Edit ? h11EditId : releaseId
			);
			const additional = res.data.data.tracks[index].additional;

			if (!additional) {
				setAdditionalReleases([]);
			} else {
				setAdditionalReleases(additional);
			}

			const donePersonal = cloneDeep(personal);
			setInitialPersonal(donePersonal);
		} catch (error) {
			console.error('An error occurred:', error);
		}
	};

	const handleISRC = (boolean) => {
		if (errors.isrc) {
			delete errors.isrc;
		}
		selectPerson.generate_isrc = !boolean;
		if (selectPerson.generate_isrc) {
			selectPerson.isrc = null;
		}
		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const handleCatalogNumber = (boolean) => {
		if (errors.catalog_number) {
			delete errors.catalog_number;
		}
		selectPerson.generate_catalog_number = !boolean;
		if (selectPerson.generate_catalog_number) {
			selectPerson.catalog_number = null;
		}
		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const changeMask = (e) => {
		setErrors({});
		selectPerson.isrc = e.target.value;
		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const changeSnippet = (field) => (e) => {
		delete errors.snippet_start;
		delete errors.snippet_end;
		delete errors.snippet_end_start;
		let endTime;

		const minutes = parseInt(e.target.value.split(':')[0]);
		const seconds = parseInt(e.target.value.split(':')[1]);

		if (minutes > 59 || seconds > 59) {
			return;
		}

		selectPerson.snippet_start =
			field === 'snippet_start' ? e.target.value : selectPerson.snippet_start;

		if (field === 'snippet_start') {
			switch (releaseTypeId.toString()) {
				case '42':
					endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
						.add(45, 'seconds')
						.format('mm:ss:SSS');
					break;
				case '43':
					endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
						.add(29.99, 'seconds')
						.format('mm:ss:SSS');
					break;
				case '70':
					if (minutes === 59) {
						endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
							.add(59, 'seconds')
							.format('mm:ss:SSS');
					} else {
						endTime = moment(`${e.target.value}`, 'mm:ss:SSS')
							.add(60, 'seconds')
							.format('mm:ss:SSS');
					}
					break;
				default:
					break;
			}
		}

		selectPerson.snippet_end =
			field === 'snippet_start' ? endTime.toString() : e.target.value;

		if (
			moment(selectPerson.snippet_end, 'mm:ss:SSS').isSameOrBefore(
				moment(selectPerson.snippet_start, 'mm:ss:SSS')
			)
		) {
			setErrors({ snippet_end_start: [{ rule: 'date_format' }] });
			return;
		}

		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const handleOnBlurDateInput = (err, value) => {
		setErrors({});

		if (err) {
			setErrors({ created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const changeField = (field) => (e) => {
		delete errors[field];

		switch (field) {
			case 'created_date':
				if (e && e.getFullYear().toString().length === 4) {
					e = new Date(e);
					e = format(e, 'yyyy-MM-dd');
				}
				selectPerson[field] = e;
				break;
			case 'genres':
				const genresArr = e.map((item) => (item.code ? item.code : item));
				selectPerson[field] = genresArr;
				break;
			case 'created_country_id':
				selectPerson[field] = e;
				break;

			default:
				selectPerson[field] = e.target.value;
				break;
		}

		personal[toogleMenu] = selectPerson;
		setPersonal([...personal]);
	};

	const toogleLastPerson = (boolean) => {
		if (boolean) {
			setErrors({});
			const lastPerson = personal[toogleMenu - 1];

			setSelectPerson({
				...selectPerson,
				genres: lastPerson.genres,
				created_country_id: lastPerson.created_country_id,
				main_performer: [...lastPerson.main_performer],
				featured_artist: [...lastPerson.featured_artist],
				party_id: lastPerson.party_id,
				producer: lastPerson.producer,
				created_date: lastPerson.created_date,
			});
		} else {
			setSelectPerson(personal[toogleMenu]);
		}

		setTakeByLastPerson(boolean);
	};

	const isValid = (toCheck, showErr = false) => {
		let errorCount = 0;
		const newError = {};

		if (!toCheck.title) {
			newError.title = 'обязательное поле';
			errorCount++;
		}

		if (!toCheck.created_date) {
			newError.created_date = [{ rule: 'required' }];
			errorCount++;
		}

		if (!toCheck.genres || toCheck.genres.length === 0) {
			newError.genres = 'обязательное поле';
			errorCount++;
		}

		if (typeof toCheck.created_country_id !== 'number') {
			newError.created_country_id = 'обязательное поле';
			errorCount++;
		}

		if (!isH11Edit && !toCheck.isrc && !toCheck.generate_isrc) {
			newError.isrc = 'обязательное поле';
			errorCount++;
		}

		if (toCheck.isrc?.includes('_') && !toCheck.generate_isrc) {
			newError.isrc = 'обязательное поле';
			errorCount++;
		}

		if (
			!transferRelease &&
			!isH11Edit &&
			!toCheck.catalog_number &&
			!toCheck.generate_catalog_number
		) {
			newError.catalog_number = 'обязательное поле';
			errorCount++;
		}

		if (
			!transferRelease &&
			!isH11Edit &&
			toCheck.catalog_number?.includes('_') &&
			!toCheck.generate_catalog_number
		) {
			newError.catalog_number = 'обязательное поле';
			errorCount++;
		}

		if (
			!toCheck.main_performer ||
			(Array.isArray(toCheck.main_performer) &&
				(!toCheck.main_performer.length ||
					toCheck.main_performer.every((item) => item === '')))
		) {
			newError.main_performer = 'обязательное поле';
			errorCount++;
		}

		if (!toCheck.producer && !toCheck.party_id) {
			newError.party_id = 'обязательное поле';
			errorCount++;
		}

		if (
			isSnippedRender &&
			!toCheck.snippet_start &&
			releaseData.step !== 'h11'
		) {
			errorCount++;
			newError.snippet_start = [{ rule: 'required' }];
		}

		if (!errorCount) {
			if (!isH11Edit) {
				setErrors({});
			}
			return false;
		} else {
			if (showErr) {
				setErrors(newError);
				const eventName = EVENTS.releaseValidationError;
				const formName = 'Форма треки, валидация';
				reachGoal(eventName);
				const pageUrl = isH11Edit
					? `/release/${releaseId}/edit/tracks`
					: `/release/${releaseId}/tracks`;
				const yaParams = {
					[pageUrl]: {
						[eventName]: {
							[formName]: {
								[accId]: accountId,
								[userId]: user.id,
								[sectionTitle]: 'Раздел "Треки (фонограммы)"',
								[errorName]: newError,
								[sourceName]: newError,
							},
						},
					},
				};
				reachParams(yaParams);
				reachDataLayer(eventName, formName, newError, userId, accountId);
			}
			return true;
		}
	};

	const recordingSave = () => {
		if (isValid(selectPerson, true)) return;

		setDisableSaveBtn(true);
		setUnsavedChangesModalShowed(false);

		if (!isSnippedRender) {
			selectPerson.snippet_start && delete selectPerson.snippet_start;
			selectPerson.snippet_end && delete selectPerson.snippet_end;
		}

		const clone = cloneDeep(selectPerson);
		clone.account_id = accountId;

		if (clone.genres.length > 0) {
			clone.genres = clone.genres.map((item) => item.code);
		}

		if (clone.created_date) {
			const time = moment().format();
			clone.created_date = getCreatedDateString(time, clone.created_date);
		}

		if (clone.isrc) {
			const preparedIsrc = clone.isrc.split('-').join('').toLowerCase();
			clone.isrc = preparedIsrc;
		}

		if (additionalReleases.length) {
			clone.additional = additionalReleases;
		}

		if (clone.main_performer) {
			const performersArr = [...clone.main_performer];
			const formattedArr = performersArr.map((item) =>
				item.artist_id
					? item.artist_id
					: item.name
					? item.name
					: (typeof item === 'string' && item !== '') ||
					  (typeof item === 'number' && item !== null)
					? item
					: ''
			);
			clone.main_performer = formattedArr.filter((item) => item !== '');
		}

		if (clone.featured_artist) {
			const performersArr = [...clone.featured_artist];
			const formattedArr = performersArr.map((item) =>
				item.artist_id
					? item.artist_id
					: item.name
					? item.name
					: (typeof item === 'string' && item !== '') ||
					  (typeof item === 'number' && item !== null)
					? item
					: ''
			);
			clone.featured_artist = formattedArr.filter((item) => item !== '');
		}

		setDisableBtn(true);
		accounts
			.uploadRecordingInfo(
				h11EditId && isH11Edit ? h11EditId : releaseId,
				selectPerson.id,
				clone
			)
			.then((res) => {
				res = res.data.data;
				if (res.isrc) {
					const data = res.isrc.replace(
						/(\d{2})\.(\d{3})\.(\d{2})\.(\d{5})/,
						'$1-$2-$3-$4'
					);

					selectPerson.isrc = data;
				}
				selectPerson.done = true;
				personal[toogleMenu] = selectPerson;
				setPersonal(personal);
				const savedPersonal = cloneDeep(personal);
				setInitialPersonal(savedPersonal);

				if (personal[toogleMenu + 1]) {
					if (!isValid(personal[toogleMenu + 1])) {
						setToogleMenu('');
					} else {
						setToogleMenu(toogleMenu + 1);
					}
				} else {
					setToogleMenu('');
					setDisabledNextBtn(false);
				}
				setDisableSaveBtn(false);
			})
			.then(() => {
				const ellementToScroll = document.getElementById('general');
				if (ellementToScroll) {
					ellementToScroll.scrollIntoView({
						behavior: 'smooth',
						block: 'end',
						inline: 'nearest',
					});
				}
			})
			.catch((err) => {
				console.error('Error', err.response.data.errors);
				console.error('Error', err.response.data);
				setErrors(err.response.data.errors);
				setDisableSaveBtn(false);
			})
			.finally(() => setDisableBtn(false));
	};

	const nextHandler = () => {
		const formattedPersonal = toGeneralFormat(personal);
		const formattedInitialPersonal = toGeneralFormat(initialPersonal);
		const isChanged =
			JSON.stringify(formattedPersonal) !==
			JSON.stringify(formattedInitialPersonal);

		if (isChanged && !unsavedChangesModalShowed) {
			setUnsavedChangesModalShowed(true);
			showModal(
				{
					text: <FormattedMessage id={'rod.modal.unsaved_changes_info.text'} />,
					withClose: true,
					confirmBtnTxt: <FormattedMessage id={'rod.modal.understood'} />,
				},
				ModalTypes.FULL_MODAL
			)();
		} else {
			setDisableBtn(true);
			if (typeof props.onChange === 'function') {
				const validDate = personal[0]?.created_date?.includes('/')
					? personal[0]?.created_date?.replace(/\//g, '-')
					: personal[0]?.created_date;
				personal.created_date = validDate;
				setPersonal(personal);
				audio.currentTime = 0;
				audio.pause();
				props
					.onChange(personal)
					.then()
					.catch()
					.finally(() => setDisableBtn(false));
			}
		}
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (h11EditId && isH11Edit) {
				history.push(`/release/${h11EditId}/edit/file`);
			} else {
				history.push(`/release/${releaseId}/file`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const handleChangeArtist = (data, index, field) => {
		if (errors && errors[field]) {
			delete errors[field];
		}

		let updatedPerformers = [...selectPerson[field]];
		updatedPerformers[index] = data;
		if (updatedPerformers[updatedPerformers.length - 1] !== '') {
			updatedPerformers.push('');
		}
		updatedPerformers = updatedPerformers.filter(
			(item, i) => item !== '' || i === updatedPerformers.length - 1
		);
		selectPerson[field] = updatedPerformers;
		const updatedPerson = {
			...selectPerson,
			[field]: updatedPerformers,
		};

		personal[toogleMenu] = updatedPerson;
		setPersonal([...personal]);
	};

	const handleAddArtist = (field) => {
		const updatedPerformers = [...selectPerson[field], ''];
		selectPerson[field] = updatedPerformers;

		const updatedPerson = {
			...selectPerson,
			[field]: updatedPerformers,
		};

		personal[toogleMenu] = updatedPerson;
		setPersonal([...personal]);
	};

	const handleDeleteArtist = (index, field) => {
		const updatedPerformers = selectPerson[field].filter((_, i) => i !== index);
		selectPerson[field] = updatedPerformers;

		const updatedPerson = {
			...selectPerson,
			[field]: !updatedPerformers.length ? [''] : updatedPerformers,
		};

		personal[toogleMenu] = updatedPerson;
		setPersonal([...personal]);
	};

	const handleSavePerformer = (updatedPerformer, index, field) => {
		const updatedPerformers = [...selectPerson[field]];
		updatedPerformers[index] = updatedPerformer;
		if (updatedPerformers[updatedPerformers.length - 1] !== '') {
			updatedPerformers.push('');
		}

		selectPerson[field] = updatedPerformers;
		const updatedPerson = {
			...selectPerson,
			[field]: updatedPerformers,
		};

		personal[toogleMenu] = updatedPerson;
		setPersonal([...personal]);
	};

	const handleOpenModal = (performer, index, text, field) => {
		setSelectedPerformer(!performer.name ? { name: text } : performer);
		setSelectedIndex(index);
		setIsModalActive(true);
		setArtistType(field);
	};

	const getPerformers = (track, performers) => {
		if (isH11Edit) {
			const data =
				releaseTypeId === 70 ||
				releaseTypeId === 51 ||
				releaseTypeId === 42 ||
				releaseTypeId === 43
					? performers
					: track.main_performer || [];
			return formatPerformers(data);
		} else {
			return formatPerformers(
				track.main_performer && track.main_performer.length
					? track.main_performer
					: performers && performers.length
					? performers
					: []
			);
		}
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getReleaseData(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then((res) => {
				if (res.data?.data) {
					setReleaseData(res.data.data);
				}

				if (res.data.data.isTransferRelease === 1) {
					setTransferRelease(true);
				}

				const tracks = correctDateInTracks(res.data.data.tracks);
				const releaseTypeId = res.data.data.release_type_id;

				if (
					releaseTypeId !== 51 &&
					releaseTypeId !== 2 &&
					releaseTypeId !== 64 &&
					releaseTypeId !== 69 &&
					res.data.data.tracks.some((track) => track.additional)
				) {
					setShowRangeModal(true);
				}

				setPersonal(tracks);
				setReleaseTypeId(releaseTypeId);

				let first = true;
				const {
					title,
					subtitle,
					created_country_id,
					performers,
					genres,
					additional,
				} = res.data.data;
				let countValid = 0;

				if (
					releaseTypeId === 70 ||
					releaseTypeId === 51 ||
					releaseTypeId === 42 ||
					releaseTypeId === 43
				) {
					const performersArr =
						typeof performers === 'string'
							? performers.split(',')
							: typeof performers === 'number'
							? [performers]
							: performers;

					const performersIds = performersArr.map((item) =>
						item.artist_id ? item.artist_id : item
					);
					setReleaseStepPerformers(performersIds);
				}

				tracks.forEach((track, i) => {
					track.title =
						releaseTypeId === 70 ||
						releaseTypeId === 51 ||
						releaseTypeId === 42 ||
						releaseTypeId === 43
							? title
							: track.title || null;
					track.subtitle =
						releaseTypeId === 70 ||
						releaseTypeId === 51 ||
						releaseTypeId === 42 ||
						releaseTypeId === 43
							? subtitle
							: track.subtitle || null;
					track.created_country_id =
						track.created_country_id || created_country_id;
					track.main_performer = getPerformers(track, performers);
					track.featured_artist = getFeaturedPerformers(track.featured_artist);
					track.party_id = track.party_id || null;
					track.genres = track.genres || genres;
					track.additional = track.additional || additional;

					if (
						isDone(
							track,
							res.data.data.isTransferRelease,
							isH11Edit,
							errors,
							setErrors,
							isSnippedRender,
							releaseData
						)
					) {
						track.done = true;
						countValid++;
					} else if (first) {
						setToogleMenu(i);
						first = false;
					}

					if (countValid === tracks.length) {
						setToogleMenu('');
					}

					const initialTracks = cloneDeep(tracks);
					setInitialPersonal(initialTracks);
				});
			})
			.catch((error) => {
				console.error('Error', error);
			});

		const countriesCache = lscache.get('countriesCashe');
		if (countriesCache && !isReqLive) {
			setCountries(countriesCache);
		} else {
			dictionaries
				.getCountries()
				.then((res) => {
					res = res.data.data;
					setCache('countries', 'countriesCashe', res);
					setCountries(res);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTakeByLastPerson(false);

		if (toogleMenu === '') {
			return;
		}

		if (toogleMenu - 1 >= 0) {
			if (!isValid(personal[toogleMenu - 1])) {
				setLastSavePerson(personal[toogleMenu - 1]);
			} else {
				setLastSavePerson({});
			}
		} else {
			setLastSavePerson({});
		}

		if (
			personal[toogleMenu]?.main_performer &&
			Array.isArray(personal[toogleMenu]?.main_performer)
		) {
			personal[toogleMenu].main_performer = removeEmptyNameObjects(
				personal[toogleMenu].main_performer
			);
		}

		if (
			personal[toogleMenu]?.featured_artist &&
			Array.isArray(personal[toogleMenu]?.featured_artist)
		) {
			personal[toogleMenu].featured_artist = removeEmptyNameObjects(
				personal[toogleMenu].featured_artist
			);
		}

		setSelectPerson({
			...personal[toogleMenu],
			main_performer: personal[toogleMenu]?.main_performer
				? [...personal[toogleMenu].main_performer]
				: [''],
			featured_artist: personal[toogleMenu]?.featured_artist
				? [...personal[toogleMenu].featured_artist]
				: [''],
		});

		if (!isH11Edit || personal[toogleMenu].done) {
			setErrors({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toogleMenu]);

	useEffect(() => {
		idTypesHasSnippet.includes(releaseTypeId.toString())
			? setIsSnippedRender(true)
			: setIsSnippedRender(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseTypeId]);

	useEffect(() => {
		if (selectPerson.additional) {
			setAdditionalReleases(selectPerson.additional);
		} else {
			setAdditionalReleases([]);
		}
	}, [toogleMenu, selectPerson.additional]);

	useEffect(() => {
		if (isH11Edit && selectPerson) {
			isValid(selectPerson, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectPerson]);

	useEffect(() => {
		for (const item of personal) {
			if (!item.done || isValid(item, false)) {
				setDisabledNextBtn(true);
				break;
			} else {
				setDisabledNextBtn(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personal, selectPerson, disabledNextBtn]);

	return (
		<div>
			<div className={s.page}>
				{showRangeModal && (
					<ModalWarning onClose={() => setShowRangeModal(false)} />
				)}
				<div className={s.main__wrapper}>
					<Sidebar
						toogleMenu={toogleMenu}
						personal={personal}
						handleForm={handleForm}
						disableSaveBtn={disableSaveBtn}
					/>

					{toogleMenu !== '' && (
						<TracksForm
							releaseTypeId={releaseTypeId}
							transferRelease={transferRelease}
							errors={errors}
							setErrors={setErrors}
							changeField={changeField}
							handleForm={handleForm}
							toogleMenu={toogleMenu}
							selectPerson={selectPerson}
							setSelectPerson={setSelectPerson}
							recordingSave={recordingSave}
							lastSavePerson={lastSavePerson}
							toogleLastPerson={toogleLastPerson}
							takeByLastPerson={takeByLastPerson}
							handleOnBlurDateInput={handleOnBlurDateInput}
							setTakeByLastPerson={setTakeByLastPerson}
							languages={languages}
							handleISRC={handleISRC}
							handleCatalogNumber={handleCatalogNumber}
							countries={countries}
							isSnippedRender={isSnippedRender}
							changeMask={changeMask}
							changeSnippet={changeSnippet}
							additionalReleases={additionalReleases}
							setAdditionalReleases={setAdditionalReleases}
							setShowRangeModal={setShowRangeModal}
							isMainValid={isValid}
							releaseData={releaseData}
							disableSaveBtn={disableSaveBtn}
							setDisableSaveBtn={setDisableSaveBtn}
							handleChangeArtist={handleChangeArtist}
							handleOpenModal={handleOpenModal}
							handleAddArtist={handleAddArtist}
							handleDeleteArtist={handleDeleteArtist}
							cleanErrorsField={props.cleanErrorsField}
							isH11Edit={isH11Edit}
							releaseStepPerformers={releaseStepPerformers}
						/>
					)}
				</div>
				<SidepageModal
					customWidth="434px"
					headerBottom="12px"
					active={isModalActive}
					setActive={() => setIsModalActive(false)}
				>
					<ArtistForm
						isModalActive={isModalActive}
						data={selectedPerformer}
						dataField={artistType}
						index={selectedIndex}
						setIsModalActive={setIsModalActive}
						onSave={handleSavePerformer}
					/>
				</SidepageModal>
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn || disableBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</div>
	);
};

export default compose(withUI, withRoot, withAuth)(Tracks);
