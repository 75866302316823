// Core
import React, { useContext, useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import InputMask from 'react-input-mask';

// Context
import { UIContext } from 'contexts/UIContext';

// Services
import lscache from 'lscache';
import { dateFormat } from 'logic';
import { dictionaries } from 'services';
import { setCache } from 'services/cacheHelper/setCache';

// UI
import { FormInput } from 'components/Form';
import { AutoWrapper } from 'components';
import { PerformerItem } from 'material-design/ReleaseCreate/PerformerItem';
import ShowEditRecording from './ShowEditRecording/ShowEditRecording';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import ValidationError from './ValidationError/ValidationError';
import Error from './Error/Error';
import { SidepageModal } from 'material-design/modals';
import { ArtistForm } from 'material-design/ReleaseCreate/ArtistForm';

// Icons
import remove from 'images/close_grey.svg';

// Styles
import s from './NewRecordingForm.module.css';

const NewRecordingForm = ({
	number,
	onClickDeleteItem,
	recordingSave,
	data,
	recording,
	errors,
	setErrors,
	isEdit,
	disabledSaveBtn,
}) => {
	const title = useRef(null);
	const subtitle = useRef(null);
	const party_id = useRef(null);
	const genres = useRef(null);
	const created_date = useRef(null);
	const created_country_id = useRef(null);
	const ISRC = useRef(null);
	const catalog_number = useRef(null);
	const main_performer = useRef(null);
	const featured_artist = useRef(null);

	const fieldRefs = {
		title,
		subtitle,
		party_id,
		genres,
		created_date,
		created_country_id,
		ISRC,
		catalog_number,
		main_performer,
		featured_artist,
	};

	const [showEditRecording, setShowEditRecording] = useState(isEdit);
	const [countries, setCountries] = useState([]);
	const [newRecording, setNewRecording] = useState({});
	const [dateError, setDateError] = useState({});
	const [isReqLive] = useState(false);
	const [selectedPerformer, setSelectedPerformer] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [isModalActive, setIsModalActive] = useState(false);
	const [artistType, setArtistType] = useState('main_performer');

	const { disableRecordingBtns, setDisableRecordingBtns } = useContext(
		UIContext
	);

	const showErrorCondition = (field, rule) => {
		return (
			errors &&
			errors.id === newRecording.id &&
			(Object.keys(errors).includes(field) ||
				('status' in errors &&
					Object.keys(errors.errors).includes(field) &&
					errors.errors[field][0].rule === rule))
		);
	};

	const showRequiredErrorCondition = (field) => {
		const rule = field === 'catalog_number' ? 'required_if' : 'required';
		return showErrorCondition(field, rule);
	};

	const showIsrcErrorField = () => {
		return showErrorCondition('isrc', 'invalid');
	};

	const showCreatedDateErrorField = () => {
		return showErrorCondition('created_date', 'before_or_equal_date');
	};

	const getErrorsObject = (field) => {
		return errors &&
			'status' in errors &&
			Object.keys(errors).length > 0 &&
			errors.id === newRecording.id &&
			Object.keys(errors.errors).includes(field)
			? errors.errors
			: errors &&
			  errors.id === newRecording.id &&
			  Object.keys(errors).includes(field)
			? errors
			: {};
	};

	useEffect(() => {
		if (errors && errors.errors && Object.keys(errors.errors).length > 0) {
			for (const field of Object.keys(errors.errors)) {
				if (fieldRefs[field]?.current) {
					fieldRefs[field].current.scrollIntoView({ behavior: 'smooth' });
					break;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);

	useEffect(() => {
		if (!recording.title) {
			recording[`title`] = data.title;
			recording['main_performer'] = data.main_performer || [''];
			recording['featured_artist'] = data.featured_artist || [''];
		}
		setNewRecording(recording);
		getCountries();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const modifyTitle = (title) => {
		const exeptions = ['the', 'of', 'and', 'и'];
		const lowerTitle = title.toLowerCase();
		if (!lowerTitle.includes(' '))
			return lowerTitle.charAt(0).toUpperCase() + lowerTitle.slice(1);

		const titleAsArray = lowerTitle.split(' ');
		const modifiedTitle = titleAsArray
			.map((word) => {
				return exeptions.includes(word)
					? word
					: word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(' ');
		return modifiedTitle;
	};

	const getCountries = () => {
		const countriesCache = lscache.get('countriesCashe');
		if (countriesCache && !isReqLive) {
			const countries = countriesCache.map((obj) => {
				let result = obj;
				result.title_en = modifyTitle(obj.title_en);
				result.title_ru = modifyTitle(obj.title_ru);
				return result;
			});
			setCountries(countries);
			return countries;
		} else {
			return dictionaries
				.getCountries()
				.then((res) => {
					res = res.data.data;
					setCache('countries', 'countriesCashe', res);

					const countries = res.map((obj) => {
						let result = obj;
						result.title_en = modifyTitle(obj.title_en);
						result.title_ru = modifyTitle(obj.title_ru);
						return result;
					});

					setCountries(countries);

					return Promise.resolve(res);
				})
				.catch((error) => {
					console.error('Error', error);
					return Promise.reject();
				});
		}
	};

	const changeField = (field) => (e) => {
		if (field === `collect_rewards`) {
			newRecording[field] = e.target.checked;
			setNewRecording(newRecording);
			return;
		}

		switch (field) {
			case `created_date`:
				if (!newRecording.created_date) {
					newRecording[field] = new Date().toISOString();
					setNewRecording({ ...newRecording });
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = dateFormat(e);
				}
				newRecording[field] = e;

				break;

			case `genres`:
				const newArr = e.map((item) => (item.code ? item.code : item));
				newRecording[field] = newArr;
				break;
			case `created_country_id`:
				newRecording[field] = e;
				break;
			case `parental_warning_type`:
				newRecording[field] = e.code;
				break;

			default:
				newRecording[field] = e.target.value;
				break;
		}

		setNewRecording({ ...newRecording });
	};

	const handleCatalogNumber = (boolean) => {
		setErrors({});
		newRecording.generate_catalog_number = !boolean;
		if (newRecording.generate_catalog_number) {
			newRecording.catalog_number = '';
		}
		setNewRecording({ ...newRecording });
	};

	const hideEditRecordings = () => {
		setShowEditRecording(true);
	};

	const showRecordings = () => {
		setShowEditRecording(false);
		setDisableRecordingBtns(true);
	};

	const changeMask = (e) => {
		newRecording.isrc = e.target.value;
		setNewRecording({ ...newRecording });
	};

	const handleOnBlurDateInput = (err, value) => {
		setDateError({});

		if (err) {
			setDateError({ created_date: [{ rule: 'wrong_format' }] });
		}

		if (Date.parse(value) > Date.now()) {
			setDateError({ created_date: [{ rule: 'wrong_format' }] });
		}
	};

	const onSaveHandler = async () => {
		if (Object.keys(dateError).length) return;

		const result = await recordingSave(newRecording);

		if (result) {
			hideEditRecordings();
		} else {
			return;
		}
	};

	const handleChangeArtist = (data, index, field) => {
		if (errors && errors.errors) {
			delete errors.errors[field];
		}

		let updatedPerformers = [...newRecording[field]];
		updatedPerformers[index] = data;
		if (updatedPerformers[updatedPerformers.length - 1] !== '') {
			updatedPerformers.push('');
		}
		updatedPerformers = updatedPerformers.filter(
			(item, i) => item !== '' || i === updatedPerformers.length - 1
		);
		newRecording[field] = updatedPerformers;
		const updatedPerson = {
			...newRecording,
			[field]: updatedPerformers,
		};
		setNewRecording({ ...updatedPerson });
	};

	const handleAddArtist = (field) => {
		const updatedPerformers = [...newRecording[field], ''];
		newRecording[field] = updatedPerformers;

		const updatedPerson = {
			...newRecording,
			[field]: updatedPerformers,
		};

		setNewRecording({ ...updatedPerson });
	};

	const handleDeleteArtist = (index, field) => {
		const updatedPerformers = newRecording[field].filter((_, i) => i !== index);
		newRecording[field] = updatedPerformers;

		const updatedPerson = {
			...newRecording,
			[field]: !updatedPerformers.length ? [''] : updatedPerformers,
		};

		setNewRecording({ ...updatedPerson });
	};

	const handleSavePerformer = (updatedPerformer, index, field) => {
		const updatedPerformers = [...newRecording[field]];
		updatedPerformers[index] = updatedPerformer;
		if (updatedPerformers[updatedPerformers.length - 1] !== '') {
			updatedPerformers.push('');
		}

		newRecording[field] = updatedPerformers;
		const updatedPerson = {
			...newRecording,
			[field]: updatedPerformers,
		};

		setNewRecording({ ...updatedPerson });
	};

	const handleOpenModal = (performer, index, text, field) => {
		setSelectedPerformer(!performer.name ? { name: text } : performer);
		setSelectedIndex(index);
		setIsModalActive(true);
		setArtistType(field);
	};

	return (
		<div>
			{showEditRecording ? (
				<ShowEditRecording
					newRecording={newRecording}
					showRecordings={showRecordings}
					onClickDeleteItem={onClickDeleteItem}
					disableRecordingBtns={disableRecordingBtns}
				/>
			) : (
				<div className={s.main}>
					<div className={s.form_container}>
						<h2 className={s.title}>
							<FormattedMessage
								id={'rod.performance_work.title'}
								values={{ number: number }}
							/>
						</h2>

						{((number === 1 && newRecording.main_performer) || number > 1) && (
							<button onClick={onClickDeleteItem} className={s.delete_svg}>
								<img src={remove} alt="" />
							</button>
						)}

						<ul className={s.list}>
							<li className={s.list_item} ref={title}>
								<FormInput
									type="muiInput"
									name={`title`}
									onChange={changeField}
									errors={getErrorsObject('title')}
									data={newRecording}
									label={
										<FormattedMessage
											id={'rod.performance_work.name_recordings'}
										/>
									}
									required
								/>
								{showRequiredErrorCondition('title') && (
									<Error id={`rod.error.required`} />
								)}
								<ValidationError
									errors={errors}
									newRecording={newRecording}
									field="title"
								/>
							</li>
							<li className={s.list_item} ref={subtitle}>
								<FormInput
									type="muiInput"
									name={`subtitle`}
									onChange={changeField}
									errors={getErrorsObject('subtitle')}
									data={newRecording}
									label={<FormattedMessage id={'rod.basic_info.subtitle'} />}
								/>
								<ValidationError
									errors={errors}
									newRecording={newRecording}
									field="subtitle"
								/>
							</li>
							<li className={s.list_item} ref={party_id}>
								<FormInput
									type="muiInput"
									name={`party_id`}
									onChange={changeField}
									errors={getErrorsObject('party_id')}
									data={newRecording}
									label={<FormattedMessage id={'rod.performance_work.label'} />}
									minWidth={352}
								/>
								<ValidationError
									errors={errors}
									newRecording={newRecording}
									field="party_id"
								/>
								<p className={s.description}>
									<FormattedMessage
										id={'rod.performance_work.main_performer.description'}
									/>
								</p>
							</li>
							<li className={s.list_item} ref={genres}>
								<FormInput
									type={'muiAutocompleteMultipleGenres'}
									name={`genres`}
									onChange={changeField}
									errors={getErrorsObject('genres')}
									data={newRecording}
									label={<FormattedMessage id={'rod.basic_info.genres'} />}
								/>
								<p className={s.description}>
									<FormattedMessage id={'rod.basic_info.genres_description'} />
								</p>
								{errors && Object.keys(getErrorsObject('genres')).length > 0 && (
									<>
										{getErrorsObject('genres')?.genres[0]?.rule ===
											'max_size' && (
											<span className={s.error}>
												{getErrorsObject('genres')?.genres[0] && (
													<FormattedMessage
														id={'rod.error.max_size.genres'}
														values={{
															value: getErrorsObject('genres')?.genres[0]
																?.value,
														}}
													/>
												)}
											</span>
										)}
									</>
								)}
							</li>
							<div className={s.input}>
								<li className={s.list_item} ref={created_date}>
									<FormInput
										type={'muiInputDate_v2'}
										name={`created_date`}
										onChange={changeField}
										handleOnBlurDateInput={handleOnBlurDateInput}
										maxDate={true}
										errors={
											(dateError &&
												Object.keys(dateError).includes('created_date')) ||
											showCreatedDateErrorField()
												? dateError
												: {}
										}
										data={newRecording}
										label={<FormattedMessage id={'rod.basic_info.date'} />}
									/>
								</li>
								{((dateError &&
									Object.keys(dateError).includes('created_date')) ||
									showCreatedDateErrorField()) && (
									<Error id={`rod.error.invalid`} />
								)}
							</div>
							<li
								className={`${s.list_item} ${s.countryItem}`}
								ref={created_country_id}
							>
								<FormattedMessage id={'rod.basic_info.country_name'}>
									{(placeholder) => (
										<FormInput
											type="country"
											name={`created_country_id`}
											onChange={changeField}
											errors={errors}
											data={newRecording}
											items={countries}
											countries={countries}
											placeholder={placeholder}
										/>
									)}
								</FormattedMessage>
							</li>
							<li className={s.list_item} ref={catalog_number}>
								<div className={s.catalog_number_container}>
									<div className={s.catalog_number}>
										<FormInput
											type="muiInput"
											name={'catalog_number'}
											onChange={changeField}
											errors={getErrorsObject('catalog_number')}
											data={newRecording}
											required
											disabled={newRecording.generate_catalog_number}
											label={
												<FormattedMessage
													id={
														'rod.release.create.step.release.label.catalog_number'
													}
												/>
											}
										/>
										{errors &&
											errors.id === newRecording.id &&
											(Object.keys(errors).includes('catalog_number') ||
												('status' in errors &&
													Object.keys(errors.errors).includes(
														'catalog_number'
													) &&
													(errors.errors['catalog_number'][0].rule ===
														'required_if' ||
														errors.errors['catalog_number'][0].rule ===
															'required_without'))) && (
												<Error id={`rod.error.required`} />
											)}
										<ValidationError
											errors={errors}
											newRecording={newRecording}
											field="catalog_number"
										/>
									</div>
								</div>
							</li>
							<li className={s.list_item}>
								<div className={s.autoWrapper}>
									<AutoWrapper
										onClick={() =>
											handleCatalogNumber(newRecording?.generate_catalog_number)
										}
										check={newRecording.generate_catalog_number}
									/>
								</div>
							</li>
							<li className={s.list_item} ref={ISRC}>
								<InputMask
									mask={'aa-***-99-99999'}
									name={`ISRC`}
									onChange={changeMask}
									errors={showIsrcErrorField()}
									value={newRecording.isrc ? newRecording.isrc : ''}
								>
									{() => (
										<TextFieldInput
											variant="standard"
											type="muiInput"
											name={`ISRC`}
											error={showIsrcErrorField()}
											value={newRecording.isrc ? newRecording.isrc : ''}
											label="ISRC"
										/>
									)}
								</InputMask>
								{showIsrcErrorField() && <Error id={`rod.error.invalid`} />}
								<p className={s.description}>
									<FormattedMessage
										id={'rod.performance_work.iswc.description'}
									/>
								</p>
							</li>
						</ul>
						<ul className={s.grid}>
							<div
								className={`${s.artistsBlock} ${s.performerError}`}
								ref={main_performer}
							>
								{newRecording.main_performer &&
									newRecording.main_performer.length > 0 &&
									newRecording.main_performer.map((performer, index) => (
										<PerformerItem
											key={index}
											performer={performer}
											index={index}
											lastIndex={newRecording.main_performer.length - 1}
											personal={newRecording}
											handleChangeArtist={handleChangeArtist}
											errors={getErrorsObject('main_performer')}
											cleanErrorsField={() => {}}
											handleOpenModal={handleOpenModal}
											handleAddArtist={handleAddArtist}
											handleDeleteArtist={handleDeleteArtist}
											styles={{ width: '100%' }}
											dataField="main_performer"
											label={
												index === 0
													? 'rod.performance_work.main_performer.required'
													: 'rod.performance_work.main_performer'
											}
										/>
									))}
								{showRequiredErrorCondition('main_performer') && (
									<span className={s.helperPerformer}>
										{<FormattedMessage id="rod.error.required" />}
									</span>
								)}
								<ValidationError
									errors={errors}
									newRecording={newRecording}
									field="main_performer"
								/>
							</div>
							<div className={s.artistsBlock} ref={featured_artist}>
								{newRecording.featured_artist &&
									newRecording.featured_artist.length > 0 &&
									newRecording.featured_artist.map((performer, index) => (
										<PerformerItem
											key={index}
											performer={performer}
											index={index}
											lastIndex={newRecording.featured_artist.length - 1}
											personal={newRecording}
											handleChangeArtist={handleChangeArtist}
											errors={getErrorsObject('featured_artist')}
											cleanErrorsField={() => {}}
											handleOpenModal={handleOpenModal}
											handleAddArtist={handleAddArtist}
											handleDeleteArtist={handleDeleteArtist}
											styles={{ width: '100%' }}
											label="rod.performance_work.featured_artist"
											dataField="featured_artist"
										/>
									))}
								<ValidationError
									errors={errors}
									newRecording={newRecording}
									field="featured_artist"
								/>
							</div>
						</ul>
					</div>
					<div>
						<button
							className={s.btn_save}
							disabled={disabledSaveBtn}
							style={
								disabledSaveBtn
									? { opacity: '0.5', cursor: 'not-allowed' }
									: { opacity: '1', cursor: 'pointer' }
							}
							onClick={onSaveHandler}
						>
							<FormattedMessage id={'rod.performance_work.save'} />
						</button>
						{errors &&
							Object.keys(errors).length > 0 &&
							'status' in errors &&
							errors.id === newRecording.id && (
								<Error id={`rod.performance_work.backend.error_on_save`} />
							)}
					</div>
				</div>
			)}
			<SidepageModal
				customWidth="434px"
				headerBottom="12px"
				active={isModalActive}
				setActive={() => setIsModalActive(false)}
			>
				<ArtistForm
					isModalActive={isModalActive}
					data={selectedPerformer}
					dataField={artistType}
					index={selectedIndex}
					setIsModalActive={setIsModalActive}
					onSave={handleSavePerformer}
				/>
			</SidepageModal>
		</div>
	);
};

export default NewRecordingForm;
