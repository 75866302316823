// Core
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Contexts
import { UIContext } from 'contexts/UIContext';

// MaterialComponents
import {
	AudioPlayerSlider,
	AudioPlayerSliderVolume,
} from 'material-design/MuiStyled/MuiStyled';

// Icons
import player_play from 'images/player_play.svg';
import player_pause from 'images/player_pause.svg';
import player_volume from 'images/player_volume.svg';
import player_volume_mute from 'images/player_volume_mute.svg';
import player_close from 'images/player_close.svg';

// Styles
import s from './audioPlayer.module.css';

const AudioPlayer = (props) => {
	const context = useContext(UIContext);
	const savedVolume = localStorage.getItem('volume');

	const {
		play,
		handleSetPlay,
		setIsPlayerShow,
		song,
		setAudioInPlay,
		isPlayerAlreadyShowed,
		handleIsPlayerAlreadyShowed,
		isPlayerShow,
	} = context;

	const [audio, setAudio] = useState(new Audio(''));
	const [volume, setVolume] = useState(savedVolume ?? audio.volume);
	const [prevVolume, setPrevVolume] = useState(volume);
	const [isShowVolume, setIsShowVolume] = useState(false);
	const [audioInPlayerUrl, setAudioInPlayerUrl] = useState('');
	const [position, setPosition] = useState(0);
	const [intervalInPlay, setIntervalInPlay] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const formatDuration = (value) => {
		if (!value || isNaN(value)) return '0:00';
		const minute = Math.floor(value / 60);
		const secondLeft = Math.floor(value) - minute * 60;
		return `${minute}:${secondLeft <= 9 ? `0${secondLeft}` : secondLeft}`;
	}

	useEffect(() => {
		if (!isPlayerAlreadyShowed) {
			handleIsPlayerAlreadyShowed(true);
			const audio = new Audio(song.url);
			audio.volume = volume;

			audio.addEventListener('loadedmetadata', function () {
				/** it's a hack; if internet connection is slow,
				 * user start to play audio, and then logs out
				 * audio will still playing; to avoid this bug we need to check
				 *  is user logged in;
				 */
				const accountId = localStorage.getItem('accountId');
				if (!accountId) {
					return;
				}
				setAudioInPlayerUrl(song.url);
				playMusic(audio);
			});

			return () => {
				setIsPlayerShow(false);
				handleSetPlay(false);
				handleIsPlayerAlreadyShowed(false);

				audio.pause();
				audio.removeEventListener('loadedmetadata', function () {
					playMusic();
				});
			};
		} else {
			handleIsPlayerAlreadyShowed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPlayerShow]);

	useEffect(() => {
		setIsLoading(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [song]);

	useEffect(() => {
		if (isLoading) {
			changeTrack();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const changeTrack = () => {
		if (isLoading) {
			if (song.url !== audioInPlayerUrl && audioInPlayerUrl) {
				clearInterval(intervalInPlay);
				audio.pause();

				setPosition(0);
				const newAudio = new Audio(song.url);
				newAudio.volume = volume;
				setAudioInPlayerUrl(song.url);
				playMusic(newAudio);
			} else if (audioInPlayerUrl && song.url === audioInPlayerUrl) {
				audio.play();
			}
			setIsLoading(false);
		}
	};

	const playMusic = (audio = null) => {
		if (!audio) {
			audio.pause();
			setAudio(new Audio(''));
			return;
		}

		setAudio(audio);
		setAudioInPlay(audio);

		handleSetPlay(true);
		clearInterval(intervalInPlay);
		audio.play();

		const interval = setInterval(() => {
			setPosition(audio.currentTime);
			if (audio.currentTime === audio.duration) {
				clearInterval(interval);
				audio.pause();
				setPosition(0);
				handleSetPlay(false);
			}
		}, 100);

		setIntervalInPlay(interval);
	};

	useEffect(() => {
		if (isShowVolume) {
			document.addEventListener('click', handleClickOnBody);
		} else {
			document.removeEventListener('click', handleClickOnBody);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShowVolume]);

	const handleClickOnBody = useCallback((e) => {
		const volumeBox = document.getElementById('volumeBox');
		const volumeImg = document.getElementById('volumeImg');
		const volumeSlider = document.getElementById('volumeSlider');
		const volumeSliderTrack = document.getElementsByClassName(
			'MuiSlider-track'
		);
		const emptySlider = document.getElementsByClassName('MuiSlider-rail');

		if (
			e.target !== volumeBox &&
			e.target !== volumeImg &&
			e.target !== volumeSlider &&
			e.target !== volumeSliderTrack &&
			e.target !== emptySlider[0]
		) {
			setIsShowVolume(false);
		}
	}, []);

	function handlePlay() {
		if (play) {
			audio.pause();
			handleSetPlay(false);
		} else {
			playMusic(audio);
			handleSetPlay(true);
		}
	}

	function stopPlayer() {
		audio.pause();
		setIsPlayerShow(false);
	}

	const handleOnChange = (e) => {
		audio.currentTime = e.target.value;
		setPosition(audio.currentTime);
	};

	const handleOnChangeVolume = (e) => {
		audio.volume = e.target.value;
		setVolume(audio.volume);
		setPrevVolume(audio.volume);
		localStorage.setItem('volume', audio.volume);
	};

	const handleClickVolume = () => {
		if (audio.volume !== 0) {
			setPrevVolume(audio.volume);
			audio.volume = 0;
			setVolume(0);
		} else {
			audio.volume = prevVolume;
			setVolume(prevVolume);
		}
	};

	const handleShowVolume = () => {
		setIsShowVolume(true);
	};

	const handleVolumeHide = () => {
		setIsShowVolume(false);
	};

	const duration = audio.duration;

	return (
		<div className={s.playerWrapper}>
			<div className={s.songTitleWrapper}>
				{song.performers ? (
					<>
						<span className={s.playerTitle}>{song.performers} </span>
						<span className={s.playerTitle}> - </span>
						<span className={s.playerTitle}>{song.client_name}</span>
					</>
				) : (
					<>
						<span className={s.playerTitle}>{song.client_name}</span>
					</>
				)}
			</div>
			<div className={s.playerNavigationWrapper}>
				<img
					src={play ? player_pause : player_play}
					className={s.play}
					onClick={handlePlay}
					alt=""
				/>

				<div className={s.playBar}>
					<span className={s.playTime}>{formatDuration(position)}</span>

					<Box className={s.slider}>
						<AudioPlayerSlider
							aria-label="time-indicator"
							size="medium"
							value={isLoading ? 0 : position}
							min={0}
							step={1}
							max={duration ? duration : 0}
							onChange={handleOnChange}
						/>
					</Box>

					<span className={s.playTime}>
						{duration ? `${formatDuration(duration)}` : '0:00'}
					</span>
				</div>
				<div className={s.volumeBtn}>
					{isShowVolume && (
						<Box
							onMouseLeave={handleVolumeHide}
							className={s.volumeSliderBox}
							id="volumeBox"
						>
							<AudioPlayerSliderVolume
								id="volumeSlider"
								orientation="vertical"
								aria-label="time-indicator"
								size="medium"
								className={s.volumeSlider}
								value={volume}
								min={0}
								step={0.1}
								max={1}
								onChange={handleOnChangeVolume}
							/>
						</Box>
					)}
					<img
						src={volume === 0 ? player_volume_mute : player_volume}
						onMouseEnter={handleShowVolume}
						onClick={handleClickVolume}
						id="volumeImg"
						alt="volume"
					/>
				</div>
				<img
					src={player_close}
					className={s.close}
					onClick={stopPlayer}
					alt=""
				/>
			</div>
		</div>
	);
};

export default AudioPlayer;
