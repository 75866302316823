/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 09/03/2021, 20:42
 */

import Flag from 'components/Flag';
import documentTypes from 'constants/documentTypes';
import { withAuth, withLang } from 'hocs';
import update from 'immutability-helper';
import lscache from 'lscache';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { accounts, admin, contracts, dictionaries } from 'services';
import { setCache } from 'services/cacheHelper/setCache';

const RootContext = React.createContext();

class RootProvider extends React.Component {
	state = {
		account: {},
		docsFiles: [],
		reportFiles: [],
		countries: [],
		territories: [],
		territoriesWithCommond: [],
		currencies: [],
		contractSignTypes: [],
		contractPeriods: [],
		isDashboardScrolled: false,
		roles: {
			shared: [],
			self: [],
			all: [],
		},
		isReqLive: false,
		reportStatus: { id: '', status: '' },
		isReportDownloaded: false,
	};

	setReportStatus = (reportId, downloadSatus) => {
		this.setState({
			reportStatus: { id: reportId, status: downloadSatus },
		});
	};

	setIsReportDownloaded = (value) => {
		this.setState({
			isReportDownloaded: value,
		});
	};

	saveDocsFiles = (docs) => {
		this.setState({
			docsFiles: docs,
		});
	};

	saveReportFiles = (reports) => {
		this.setState({
			reportFiles: reports,
		});
	};

	handleDashboardScrolled = (param) => {
		this.setState({
			isDashboardScrolled: param,
		});
	};

	getCountryById = (id, withFlag = true) => {
		const lang = localStorage.getItem('lang');
		const item = this.state.countries.find((country) => country.id === id);
		if (item) {
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{withFlag && <Flag code={item.code.toLowerCase()} />}
					<div style={{ marginLeft: withFlag ? 10 : 0 }}>
						{lang === 'ru' ? item.title_ru : item.title_en}
					</div>
				</div>
			);
		}
		return null;
	};

	getCountryDialingCode = (id) => {
		const item = this.state.countries.find((country) => country.id === id);
		if (item) {
			return item.dialing_code;
		}
		return null;
	};

	getDocumentTitle = (keyword) => {
		switch (keyword) {
			case documentTypes.COMPANY_REGISTRATION_CERTIFICATE:
				return (
					<FormattedMessage
						id={'rod.account.create.documents.company.registration'}
					/>
				);
			case documentTypes.COMPANY_TAX_RESIDENCY_CERTIFICATE:
				return (
					<FormattedMessage id={'rod.account.create.documents.company.tax'} />
				);
			case documentTypes.PASSPORT_PHOTO_PAGE:
				return (
					<FormattedMessage
						id={'rod.account.create.documents.passport.photo_page'}
					/>
				);
			case documentTypes.PASSPORT_REGISTRATION_PAGE:
				return (
					<FormattedMessage
						id={'rod.account.create.documents.passport.address_page'}
					/>
				);
			case documentTypes.SOLE_ENTREPRENEUR_REGISTRATION_CERTIFICATE:
				return (
					<FormattedMessage
						id={'rod.account.create.documents.pe.registration'}
					/>
				);
			case 'contract-sub_publishing':
				return (
					<FormattedMessage
						id={'rod.account.create.sub-publishing-agreement'}
					/>
				);
			case 'contract-license':
				return <FormattedMessage id={'rod.account.create.license-agreement'} />;
			case 'contract-publishing':
				return (
					<FormattedMessage id={'rod.account.create.publishing-agreement'} />
				);
			default:
				return keyword;
		}
	};

	getSharedRoles = () => {
		//++
		return accounts
			.getSharedRoles()
			.then((res) => {
				res = res.data.data;
				this.setState(update(this.state, { roles: { $set: res } }));
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getSelfRoles = () => {
		//++
		return accounts
			.getSelfRoles()
			.then((res) => {
				res = res.data.data;
				this.setState(update(this.state, { roles: { $set: res } }));
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRoles = (data) => {
		//++
		return accounts
			.getRoles(data)
			.then((res) => {
				res = res.data.data;
				this.setState(update(this.state, { roles: { $set: res } }));
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRolesForAddNew = (data) => {
		//++
		return accounts
			.getRolesForAddNew(data)
			.then((res) => {
				res = res.data.data;
				this.setState(update(this.state, { roles: { $set: res } }));
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	createAccount = (data) => {
		return accounts
			.createAccount(data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getReportsPersonalInfo = (id) => {
		return accounts
			.getReportsPersonalInfo(id)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	updateReportsPersonalInfo = (id, data) => {
		return accounts
			.updateReportsPersonalInfo(id, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getAccountPersonalData = (id) => {
		const {
			authContext: { getStepForWidget, getUserData },
		} = this.props;

		return accounts
			.getAccountPersonalData(id)
			.then((res) => {
				// Extract tokens from response
				const accessToken = res.headers['x-access-token'];
				const refreshToken = res.headers['refresh-token'];

				// Store tokens in local storage if they exist
				if (accessToken) {
					localStorage.setItem('token', accessToken);
				}

				if (refreshToken) {
					localStorage.setItem('refresh', refreshToken);
				}

				if (res.data.data) {
					this.setState({ account: res.data.data });
					getStepForWidget(res.data.data.completion_step);
					getUserData();
				}

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	updateAccountPersonalData = (id, data) => {
		const {
			authContext: { getStepForWidget },
		} = this.props;
		return accounts
			.updateAccountPersonalData(id, data)
			.then((res) => {
				getStepForWidget(res.data.data.completion_step);
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	updateAccountCompanyData = (id, data) => {
		const {
			authContext: { getStepForWidget },
		} = this.props;
		return accounts
			.updateAccountCompanyData(id, data)
			.then((res) => {
				getStepForWidget(res.data.data.completion_step);
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAccountContactsData = (id) => {
		return accounts.getAccountContactsData(id).then((res) => {
			return Promise.resolve(res);
		});
	};

	updateAccountContactsData = (id, data) => {
		return accounts
			.updateAccountContactsData(id, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				console.error('error.response', err?.response);
				console.error('error.status', err?.response?.status);
				console.error('error.data', err?.response?.data?.errors);
				console.error('id', id);
				console.error('request data', data);
				throw err;
			});
	};

	getAccountPaymentData = (id) => {
		return accounts
			.getAccountPaymentData(id)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	searchArtists = (accountId, value) => {
		return accounts
			.searchArtists(accountId, value)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getArtist = (accountId, artist_id) => {
		return accounts
			.getArtist(accountId, artist_id)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	createArtist = (accountId, data) => {
		return accounts
			.createArtist(accountId, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	updateArtist = (accountId, artist_id, data) => {
		return accounts
			.updateArtist(accountId, artist_id, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	// Author societies //
	searchAuthorSocieties = (value) => {
		return accounts
			.searchAuthorSocieties(value)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getAuthorSocieties = (releaseId, recordingId, contributorId, type) => {
		return accounts
			.getAuthorSocieties(releaseId, recordingId, contributorId, type)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getCompositionAuthorSocieties = (compositionId, contributorId, type) => {
		return accounts
			.getCompositionAuthorSocieties(compositionId, contributorId, type)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	createAuthorSociety = (releaseId, recordingId, contributorId, data) => {
		return accounts
			.createAuthorSociety(releaseId, recordingId, contributorId, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	updateAuthorSociety = (releaseId, recordingId, contributorId, data) => {
		return accounts
			.updateAuthorSociety(releaseId, recordingId, contributorId, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	deleteAuthorSociety = (
		releaseId,
		recordingId,
		contributorId,
		authorsSocietyId
	) => {
		return accounts
			.deleteAuthorSociety(
				releaseId,
				recordingId,
				contributorId,
				authorsSocietyId
			)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	createCompositionAuthorSociety = (compositionId, contributorId, data) => {
		return accounts
			.createCompositionAuthorSociety(compositionId, contributorId, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};
	updateCompositionAuthorSociety = (compositionId, contributorId, data) => {
		return accounts
			.updateCompositionAuthorSociety(compositionId, contributorId, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};
	deleteCompositionAuthorSociety = (
		compositionId,
		contributorId,
		authorsSocietyId
	) => {
		return accounts
			.deleteCompositionAuthorSociety(
				compositionId,
				contributorId,
				authorsSocietyId
			)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	//=============new functions ===========
	getAccountPseudonymsData = (id) => {
		return accounts
			.getAccountPseudonymsData(id)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	createPseudonym = (accountId, data) => {
		return accounts
			.createPseudonym(accountId, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((err) => {
				console.error('err', err);
				return Promise.reject(err);
			});
	};

	getOnePseudonymData = (accountId, pseud_id) => {
		return accounts
			.getOnePseudonymData(accountId, pseud_id)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	updatePseudonym = (accId, pseudonymId, body) => {
		return accounts
			.updatePseudonym(accId, pseudonymId, body)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deletePseudonym = (accId, pseudonymId) => {
		return accounts
			.deletePseudonym(accId, pseudonymId)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	// =======================================

	sendAccountToModeration = (id) => {
		return accounts.sendAccountToModeration(id);
	};

	getAccountPseudonyms = (id) => {
		return accounts.getAccountPseudonyms(id);
	};
	createAccountPseudonyms = (id, data) => {
		return accounts.createAccountPseudonyms(id, data);
	};

	deleteAccountPseudonym = (id, pseudonymId) => {
		return accounts.deleteAccountPseudonym(id, pseudonymId);
	};
	deleteAccountSociety = (id, societyId) => {
		return accounts.deleteAccountSociety(id, societyId);
	};

	updateAccountPaymentData = (id, data) => {
		return accounts
			.updateAccountPaymentData(id, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	uploadAccountDocument = (id, data) => {
		return accounts
			.uploadAccountDocument(id, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteAccountDocument = (id, fileId) => {
		return accounts.deleteAccountDocument(id, fileId);
	};

	acceptAccountDocuments = (id, data) => {
		return accounts
			.acceptAccountDocuments(id, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAccountDocuments = (id, data) => {
		return accounts
			.getAccountDocuments(id, data)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getContractData = (id) => {
		return contracts
			.getContractData(id)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				throw error;
			});
	};

	getCountries = () => {
		const lang = localStorage.getItem('lang');
		const countriesCache = lscache.get('countriesCashe');

		if (countriesCache && !this.state.isReqLive) {
			this.setState({ countries: countriesCache });
			return countriesCache;
		} else {
			return dictionaries
				.getCountries()
				.then((res) => {
					res = res.data.data;
					const filteredCountries =
						lang === 'ru'
							? res.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1))
							: res.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));

					setCache('countries', 'countriesCashe', filteredCountries);

					this.setState({
						countries: filteredCountries,
					});
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};

	getTerritories = () => {
		const countriesWithCompoundCache = lscache.get(
			'countriesWithCompoundCache'
		);

		if (countriesWithCompoundCache && !this.state.isReqLive) {
			this.setState({ territories: countriesWithCompoundCache });
			return countriesWithCompoundCache;
		} else {
			return dictionaries
				.getCountries('with_compound')
				.then((res) => {
					res = res.data.data;
					setCache('countriesWithCompound', 'countriesWithCompoundCache', res);
					this.setState({
						territories: res,
					});
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};

	getTerritoriesWithCompound = () => {
		const countriesWithCompoundTrueCache = lscache.get(
			'countriesWithCompoundTrueCache'
		);

		if (countriesWithCompoundTrueCache && !this.state.isReqLive) {
			this.setState({ territoriesWithCommond: countriesWithCompoundTrueCache });
			return countriesWithCompoundTrueCache;
		} else {
			return dictionaries
				.getCountries('with_compound=true')
				.then((res) => {
					res = res.data.data;
					setCache(
						'countriesWithCompoundTrue',
						'countriesWithCompoundTrueCache',
						res
					);
					this.setState({
						territoriesWithCommond: res,
					});
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};

	getContractSignTypes = () => {
		return dictionaries
			.getContractSignTypes()
			.then((res) => {
				res = res.data.data;
				this.setState({
					contractSignTypes: res,
				});
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getCurrencies = () => {
		const currencyCash = lscache.get('currencyCash');
		if (currencyCash && !this.state.isReqLive) {
			this.setState({
				currencies: currencyCash,
			});
			return currencyCash;
		} else {
			return dictionaries
				.getCurrencies()
				.then((res) => {
					res = res.data.data;
					setCache('currency', 'currencyCash', res);
					this.setState({
						currencies: res,
					});
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};
	getContractPeriods = () => {
		return dictionaries
			.getContractPeriods()
			.then((res) => {
				res = res.data.data;
				this.setState({
					contractPeriods: res,
				});
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	suggestSociety = (query) => {
		return dictionaries
			.suggestSociety(query)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getContractsTerritories = (id, useType, mediaType) => {
		return contracts.getContractsTerritories(id, useType, mediaType);
	};

	updateContractsTerritories = (id, useType, mediaType, data) => {
		return contracts
			.updateContractsTerritories(id, useType, mediaType, data)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	switchToPhysical = (id) => {
		return admin
			.switchToPhysical(id)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	setAccountStep = (accountId, data) => {
		return admin
			.setAccountStep(accountId, data)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getMvpUserData = (id) => {
		return admin
			.getMvpUserData(id)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	setAllowCreateAdditionalAccountsFlag = (id) => {
		return admin
			.setAllowCreateAdditionalAccountsFlag(id)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	deleteAllowCreateAdditionalAccountsFlag = (id) => {
		return admin
			.deleteAllowCreateAdditionalAccountsFlag(id)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getFlagsWithId = (userId, accountId) => {
		return admin
			.getFlagsWithId(userId, accountId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	addFlag = (typeId, flagId, type) => {
		return admin
			.addFlag(typeId, flagId, type)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	removeFlag = (typeId, flagId, type) => {
		return admin
			.removeFlag(typeId, flagId, type)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	getSignupsUserData = (signupId) => {
		return admin
			.getSignupsUserData(signupId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteSignupsUserData = (signupId) => {
		return admin
			.deleteSignupsUserData(signupId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	deleteNotApprovedAcc = (accountId) => {
		return admin
			.deleteNotApprovedAcc(accountId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getAdminSteps = (id, page) => {
		return admin
			.getAdminSteps(id, page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getSignupsSteps = (id, page) => {
		return admin
			.getSignupsSteps(id, page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	signupEmailVerification = (signupId) => {
		return admin
			.signupEmailVerification(signupId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	signupSmsVerification = (signupId) => {
		return admin
			.signupSmsVerification(signupId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAccountStatisticReports = (accountId, page) => {
		return admin
			.getAccountStatisticReports(accountId, page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminStatisticReports = (accountId, page) => {
		return admin
			.getAdminStatisticReports(accountId, page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	// ----------------statistic ----------------------
	getAccountStatisticOutlets = () => {
		return accounts.getAccountStatisticOutlets().then((res) => {
			return Promise.resolve(res);
		});
	};

	getAccountStatisticAuthorOutlets = () => {
		return accounts.getAccountStatisticAuthorOutlets().then((res) => {
			return Promise.resolve(res);
		});
	};

	getAccountStatisticsCountries = (
		isAdmin,
		accountId,
		dateFrom,
		dateTo,
		outlets,
		tracksIds = [],
		lang
	) => {
		return accounts
			.getAccountStatisticsCountries(
				isAdmin,
				accountId,
				dateFrom,
				dateTo,
				outlets,
				tracksIds,
				lang
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticsAuthorCountries = (
		isAdmin,
		accountId,
		dateFrom,
		dateTo,
		outlets,
		tracksIds = [],
		lang
	) => {
		return accounts
			.getAccountStatisticsAuthorCountries(
				isAdmin,
				accountId,
				dateFrom,
				dateTo,
				outlets,
				tracksIds,
				lang
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticCheck = (accountId) => {
		return accounts.getAccountStatisticCheck(accountId).then((res) => {
			return Promise.resolve(res);
		});
	};

	getReleaseStatisticCheck = (accountId, releaseId) => {
		return accounts
			.getReleaseStatisticCheck(accountId, releaseId)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticTopReleases = (accountId, dateFrom, dateTo) => {
		return accounts
			.getAccountStatisticTopReleases(accountId, dateFrom, dateTo)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticTopReleasesInfo = (accountId, items = []) => {
		return accounts
			.getAccountStatisticTopReleasesInfo(accountId, items)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticTopTracks = (
		isAdmin,
		id,
		page,
		dateFrom,
		dateTo,
		outlets,
		limit,
		recordingIds,
		releaseIds,
		countries = []
	) => {
		return accounts
			.getAccountStatisticTopTracks(
				isAdmin,
				id,
				page,
				dateFrom,
				dateTo,
				outlets,
				limit,
				recordingIds,
				releaseIds,
				countries
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAuthorTopTracks = (
		isAdmin,
		id,
		page,
		dateFrom,
		dateTo,
		outlets,
		limit,
		recordingIds,
		releaseIds,
		countries = [],
		artists = []
	) => {
		return accounts
			.getAccountStatisticAuthorTopTracks(
				isAdmin,
				id,
				page,
				dateFrom,
				dateTo,
				outlets,
				limit,
				recordingIds,
				releaseIds,
				countries,
				artists
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAllTracks = (
		isAdmin,
		id,
		search,
		page,
		limit,
		dateFrom,
		dateTo,
		releaseIds
	) => {
		return accounts
			.getAccountStatisticAllTracks(
				isAdmin,
				id,
				search,
				page,
				limit,
				dateFrom,
				dateTo,
				releaseIds
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAuthorAllTracks = (
		isAdmin,
		id,
		search,
		page,
		limit,
		dateFrom,
		dateTo,
		releaseIds
	) => {
		return accounts
			.getAccountStatisticAuthorAllTracks(
				isAdmin,
				id,
				search,
				page,
				limit,
				dateFrom,
				dateTo,
				releaseIds
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAllPerformers = (
		isAdmin,
		id,
		search,
		page,
		limit,
		dateFrom,
		dateTo,
		recordingIds,
		outlets
	) => {
		return accounts
			.getAccountStatisticAllPerformers(
				isAdmin,
				id,
				search,
				page,
				limit,
				dateFrom,
				dateTo,
				recordingIds,
				outlets
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAuthorAllPerformers = (
		isAdmin,
		id,
		search,
		page,
		limit,
		dateFrom,
		dateTo,
		recordingIds,
		outlets
	) => {
		return accounts
			.getAccountStatisticAuthorAllPerformers(
				isAdmin,
				id,
				search,
				page,
				limit,
				dateFrom,
				dateTo,
				recordingIds,
				outlets
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAllRecordings = (
		isAdmin,
		id,
		search,
		page,
		limit,
		dateFrom,
		dateTo,
		releaseIds,
		recordingIds,
		outlets
	) => {
		return accounts
			.getAccountStatisticAllRecordings(
				isAdmin,
				id,
				search,
				page,
				limit,
				dateFrom,
				dateTo,
				releaseIds,
				recordingIds,
				outlets
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAuthorAllRecordings = (
		isAdmin,
		id,
		search,
		page,
		limit,
		dateFrom,
		dateTo,
		artists,
		recordingIds,
		outlets
	) => {
		return accounts
			.getAccountStatisticAuthorAllRecordings(
				isAdmin,
				id,
				search,
				page,
				limit,
				dateFrom,
				dateTo,
				artists,
				recordingIds,
				outlets
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAllReleases = (
		isAdmin,
		id,
		search,
		page,
		limit,
		dateFrom,
		dateTo,
		artists = []
	) => {
		return accounts
			.getAccountStatisticAllReleases(
				isAdmin,
				id,
				search,
				page,
				limit,
				dateFrom,
				dateTo,
				artists
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticStreams = (
		isAdmin,
		id,
		dateFrom,
		dateTo,
		outlets,
		recordingIds,
		releaseIds,
		all
	) => {
		return accounts
			.getAccountStatisticStreams(
				isAdmin,
				id,
				dateFrom,
				dateTo,
				outlets,
				recordingIds,
				releaseIds,
				all
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};
	getAccountStatisticStreamsV2 = (
		isAdmin,
		id,
		dateFrom,
		dateTo,
		outlets,
		recordingIds,
		releaseIds,
		all
	) => {
		return accounts
			.getAccountStatisticStreamsV2(
				isAdmin,
				id,
				dateFrom,
				dateTo,
				outlets,
				recordingIds,
				releaseIds,
				all
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAuthorStreams = (
		isAdmin,
		id,
		dateFrom,
		dateTo,
		outlets,
		recordingIds,
		releaseIds,
		all
	) => {
		return accounts
			.getAccountStatisticAuthorStreams(
				isAdmin,
				id,
				dateFrom,
				dateTo,
				outlets,
				recordingIds,
				releaseIds,
				all
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticMap = (isAdmin, id) => {
		return accounts.getAccountStatisticMap(isAdmin, id).then((res) => {
			return Promise.resolve(res);
		});
	};

	getAccountStatisticMapByDate = (
		isAdmin,
		id,
		dateFrom,
		dateTo,
		outlets,
		recordingIds,
		all,
		countries
	) => {
		return accounts
			.getAccountStatisticMapByDate(
				isAdmin,
				id,
				dateFrom,
				dateTo,
				outlets,
				recordingIds,
				all,
				countries
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticAuthorMapByDate = (
		isAdmin,
		id,
		dateFrom,
		dateTo,
		outlets,
		recordingIds,
		all,
		countries
	) => {
		return accounts
			.getAccountStatisticAuthorMapByDate(
				isAdmin,
				id,
				dateFrom,
				dateTo,
				outlets,
				recordingIds,
				all,
				countries
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountStatisticsColumnar = async (
		isAdmin,
		accountId,
		outlets,
		dateFrom,
		dateTo,
		recordingIds = [],
		perPage,
		page,
		sort,
		countries = []
	) => {
		return await accounts.getAccountStatisticsColumnar(
			isAdmin,
			accountId,
			outlets,
			dateFrom,
			dateTo,
			recordingIds,
			perPage,
			page,
			sort,
			countries
		);
	};

	getAccountStatisticsAuthorColumnar = async (
    isAdmin,
		accountId,
		outlets,
		dateFrom,
		dateTo,
		recordingIds = [],
		perPage,
		page,
		sort,
		countries = []
	) => {
		return await accounts.getAccountStatisticsAuthorColumnar(
      isAdmin,
			accountId,
			outlets,
			dateFrom,
			dateTo,
			recordingIds,
			perPage,
			page,
			sort,
			countries
		);
	};

	getAccountRecordingsInPlaylists = (
		accountId,
		page,
		recordingIds,
		dateFrom,
		dateTo
	) => {
		return accounts
			.getAccountRecordingsInPlaylists(
				accountId,
				page,
				recordingIds,
				dateFrom,
				dateTo
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountRecordingPageInPlaylists = (
		accountId,
		recordingId,
		releaseId,
		outlet,
		page,
		dateFrom,
		dateTo,
		countries = []
	) => {
		return accounts
			.getAccountRecordingPageInPlaylists(
				accountId,
				recordingId,
				releaseId,
				outlet,
				page,
				dateFrom,
				dateTo,
				countries
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountRecordingsFilterPlaylist = (
		isAdmin,
		accountId,
		search,
		page,
		limit,
		dateFrom,
		dateTo
	) => {
		return accounts
			.getAccountRecordingsFilterPlaylist(
				isAdmin,
				accountId,
				search,
				page,
				limit,
				dateFrom,
				dateTo
			)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountCountriesFilterPlaylist = (accountId, search) => {
		return accounts
			.getAccountCountriesFilterPlaylist(accountId, search)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	getAccountContracts = (licensor_account_id) => {
		return contracts.getAccountContracts(licensor_account_id).then((res) => {
			return Promise.resolve(res);
		});
	};

	getContractCondition = (contractId) => {
		return contracts.getContractCondition(contractId).then((res) => {
			return Promise.resolve(res);
		});
	};

	getContractAttachments = (contractId) => {
		return contracts.getContractAttachments(contractId).then((res) => {
			return Promise.resolve(res);
		});
	};

	getContractById = (contractId) => {
		return contracts.getContractById(contractId).then((res) => {
			return Promise.resolve(res);
		});
	};

	getAdminStatisticService = (outlets, dateFrom, dateTo, isReady, page) => {
		return admin
			.getAdminStatisticService(outlets, dateFrom, dateTo, isReady, page)
			.then((res) => {
				return Promise.resolve(res);
			});
	};

	// --------------------------------------------------

	getRecordingMetadata = (accountId, releaseId) => {
		return accounts
			.getRecordingMetadata(accountId, releaseId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getCompositionMetadata = (accountId, releaseId) => {
		return accounts
			.getCompositionMetadata(accountId, releaseId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRecordingCover = (accountId, releaseId) => {
		return accounts
			.getRecordingCover(accountId, releaseId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRecordingAudio = (accountId, releaseId) => {
		return accounts
			.getRecordingAudio(accountId, releaseId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getCompositionRecordings = (accountId, compositionId) => {
		return accounts
			.getCompositionRecordings(accountId, compositionId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRecordingIssues = (accountId, releaseId, lang) => {
		return accounts
			.getRecordingIssues(accountId, releaseId, lang)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getCompositionIssues = (accountId, compositionId, lang) => {
		return accounts
			.getCompositionIssues(accountId, compositionId, lang)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRecordingReleases = (accountId, releaseId) => {
		return accounts
			.getRecordingReleases(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getCompositionReleases = (accountId, compositionId) => {
		return accounts
			.getCompositionReleases(accountId, compositionId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRecordingOwnership = (
		accountId,
		recordingId,
		countryId,
		exploitationId,
		rightId /* query[right_id] */,
		outletId /* query[outlet_id] */,
		productId /* query[product_id] */,
		dateFrom,
		lang
	) => {
		return accounts
			.getRecordingOwnership(
				accountId,
				recordingId,
				countryId,
				exploitationId,
				rightId /* query[right_id] */,
				outletId /* query[outlet_id] */,
				productId /* query[product_id] */,
				dateFrom,
				lang
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getCompositionOwnership = (
		accountId,
		compositionId,
		countryId,
		exploitationId,
		rightId /* query[right_id] */,
		outletId /* query[outlet_id] */,
		productId /* query[product_id] */,
		dateFrom,
		lang
	) => {
		return accounts
			.getCompositionOwnership(
				accountId,
				compositionId,
				countryId,
				exploitationId,
				rightId /* query[right_id] */,
				outletId /* query[outlet_id] */,
				productId /* query[product_id] */,
				dateFrom,
				lang
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRecordingOutlets = (
		accountId,
		releaseId,
		lang,
		countryId,
		exploitationId,
		rightId
	) => {
		return accounts
			.getCompositionOutlets(
				accountId,
				releaseId,
				lang,
				countryId,
				exploitationId,
				rightId
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getCompositionOutlets = (
		accountId,
		releaseId,
		lang,
		countryId,
		exploitationId,
		rightId
	) => {
		return accounts
			.getCompositionOutlets(
				accountId,
				releaseId,
				lang,
				countryId,
				exploitationId,
				rightId
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRecordingProducts = (accountId, releaseId, id, lang) => {
		return accounts
			.getRecordingProducts(accountId, releaseId, id, lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getCompositionProducts = (accountId, releaseId, id, lang) => {
		return accounts
			.getCompositionProducts(accountId, releaseId, id, lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getGenres = () => {
		const genresCache = lscache.get('genresCache');
		if (genresCache && !this.state.isReqLive) {
			return genresCache;
		} else {
			return accounts
				.getGenres()
				.then((res) => {
					res = res.data.data;
					setCache('genres', 'genresCache', res);
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};

	getExploitationTypes = () => {
		return dictionaries
			.getExploitationTypes()
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRecordingTypes = () => {
		return dictionaries
			.getRecordingTypes()
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getCompositionTypes = () => {
		return dictionaries
			.getCompositionTypes()
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getCommercialModels = () => {
		return dictionaries
			.getCommercialModels()
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRecordingAudio = (accountId, releaseId) => {
		return accounts
			.getRecordingAudio(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getReleaseTypes = (lang) => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (
			releaseTypesCache &&
			!this.state.isReqLive &&
			releaseTypesCache.lang === lang
		) {
			return releaseTypesCache.data;
		} else {
			return accounts
				.getReleaseTypes(lang)
				.then((res) => {
					res = res.data.data;
					setCache('releaseTypes', 'releaseTypesCache', res);
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};

	getReleaseTypesAll = (lang) => {
		const releaseTypesAllCache = lscache.get('releaseTypesAllCache');
		if (
			releaseTypesAllCache &&
			!this.state.isReqLive &&
			releaseTypesAllCache.lang === lang
		) {
			return Promise.resolve(releaseTypesAllCache.data);
		} else {
			return accounts
				.getReleaseTypesAll(lang)
				.then((res) => {
					res = res.data.data;
					setCache('releaseTypesAll', 'releaseTypesAllCache', res);
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};

	getLanguages = () => {
		const languagesCache = lscache.get('languagesCache');
		if (languagesCache && !this.state.isReqLive) {
			return languagesCache;
		} else {
			return accounts
				.getLanguages()
				.then((res) => {
					res = res.data.data;
					setCache('languages', 'languagesCache', res);
					return Promise.resolve(res);
				})
				.catch((error) => {
					console.info('Error', error);
					throw error;
				});
		}
	};

	getCompositionsOriginRightholders = (accountId, compositionId) => {
		return accounts
			.getCompositionsOriginRightholders(accountId, compositionId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRecordingsOriginRightholders = (accountId, recordingId) => {
		return accounts
			.getRecordingsOriginRightholders(accountId, recordingId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getContributorRoles = (lang) => {
		return dictionaries
			.getContributorRoles(lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getContributorRolesByAccId = (accountId, lang) => {
		return dictionaries
			.getContributorRolesByAccId(accountId, lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getCompositionsRevenue = (
		accountId,
		compositionId,
		partyId,
		requestParameters
	) => {
		return accounts
			.getCompositionsRevenue(
				accountId,
				compositionId,
				partyId,
				requestParameters
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRecordingsRevenue = (
		accountId,
		recordingId,
		partyId,
		requestParameters
	) => {
		return accounts
			.getRecordingsRevenue(accountId, recordingId, partyId, requestParameters)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getFaqList = (lang) => {
		return accounts
			.getFaqList(lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getFaqSearchByText = (text, lang) => {
		return accounts
			.getFaqSearchByText(text, lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getFaqSearchBySectionId = (sectionId, lang) => {
		return accounts
			.getFaqSearchBySectionId(sectionId, lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getFaqSearchByArticleId = (sectionId, articleId, lang) => {
		return accounts
			.getFaqSearchByArticleId(sectionId, articleId, lang)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	//==========statistic reports - uploading============
	createStatisticAccountReport = (
		accountId,
		dateFrom,
		dateTo,
		outlets,
		recordingIds = [],
		type
	) => {
		return accounts
			.createStatisticAccountReport(
				accountId,
				dateFrom,
				dateTo,
				outlets,
				recordingIds,
				type
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	createStatisticAccountSummaryReport = (
		accountId,
		dateFrom,
		dateTo,
		outlets,
		recordingIds,
		releaseIds,
		type
	) => {
		return accounts
			.createStatisticAccountSummaryReport(
				accountId,
				dateFrom,
				dateTo,
				outlets,
				recordingIds,
				releaseIds,
				type
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getRespStatisticAccountReports = (accountId) => {
		return accounts
			.getRespStatisticAccountReports(accountId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getStatisticAccountReportFile = (accountId, id) => {
		return accounts
			.getStatisticAccountReportFile(accountId, id)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	createStatisticAdminReport = (accountId, dateFrom, dateTo, outlets) => {
		return accounts
			.createStatisticAdminReport(accountId, dateFrom, dateTo, outlets)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getRespStatisticAdminReports = (accountId) => {
		return accounts
			.getRespStatisticAdminReports(accountId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getStatisticAdminReportFile = (accountId, id) => {
		return accounts
			.getStatisticAdminReportFile(accountId, id)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getAdminNotifications = (page, search, lang) => {
		return admin
			.getAdminNotifications(page, search, lang)
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	createAdminNotifications = (
		lang,
		heading,
		text,
		massMailing,
		recipients,
		fd
	) => {
		return admin
			.createAdminNotifications(
				lang,
				heading,
				text,
				massMailing,
				recipients,
				fd
			)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getUsersAdminV2 = () => {
		return admin
			.getUsersAdminV2()
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteUsersAdmin = (ids) => {
		return admin
			.deleteUsersAdmin(ids)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getUserNotifications = (accountId) => {
		return accounts
			.getUserNotifications(accountId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	putReadUserNotification = (accountId, id) => {
		return accounts
			.putReadUserNotification(accountId, id)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getUserNotificationsSettings = (accountId) => {
		return accounts
			.getUserNotificationsSettings(accountId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	putUserNotificationsSettings = (accountId, data) => {
		return accounts
			.putUserNotificationsSettings(accountId, data)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getAdminArticles = (page, search, lang) => {
		return admin
			.getAdminArticles(page, search, lang)
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getAdminArticlesRubrics = (lang, page) => {
		return admin
			.getAdminArticlesRubrics(lang, page)
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminArticlesSectionsAll = () => {
		return admin
			.getAdminArticlesSectionsAll()
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	addAdminArticlesRubric = (rubric) => {
		return admin
			.addAdminArticlesRubric(rubric)
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	updateAdminArticlesRubric = (sectionId, rubric) => {
		return admin
			.updateAdminArticlesRubric(sectionId, rubric)
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteAdminArticlesRubric = (sectionId) => {
		return admin
			.deleteAdminArticlesRubric(sectionId)
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminArticlesSectionsTranslate = (slug, lang) => {
		return admin
			.getAdminArticlesSectionsTranslate(slug, lang)
			.then((res) => {
				res = res.data;

				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRecordingsCompositions = (accountId, recordingId) => {
		return accounts
			.getRecordingsCompositions(accountId, recordingId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getAccountReports = (accountId, page) => {
		return accounts
			.getAccountReports(accountId, page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAccountPaymentsInfo = async (accountId) => {
		try {
			const { data } = await accounts.getAccountPaymentsInfo(accountId);
			return data.data;
		} catch (error) {
			console.info('Error', error);
			throw error;
		}
	};

	getAccountPaymentsReports = async (accountId) => {
		try {
			const { data } = await accounts.getAccountPaymentsReports(accountId);
			return data.data;
		} catch (error) {
			console.error('Error', error);
			throw error;
		}
	};

	getAccountPaymentsReportById = async (accountId, id) => {
		try {
			const { data } = await accounts.getAccountPaymentsReportById(
				accountId,
				id
			);
			return data.data;
		} catch (error) {
			console.error('Error', error);
			throw error;
		}
	};

	getAccountPayments = async (accountId, page, limit = 10) => {
		try {
			const { data } = await accounts.getAccountPayments(
				accountId,
				page,
				limit
			);
			return data;
		} catch (error) {
			console.info('Error', error);
			throw error;
		}
	};

	getPaymentInvoice = async (accountId, paymentId) => {
		try {
			const { data } = await accounts.getAccountPaymentInvoice(
				accountId,
				paymentId
			);
			return data.data;
		} catch (error) {
			console.info('Error', error);
			throw error;
		}
	};

	createInvoice = async (accountId, signType = null, invoiceId = null, fd) => {
		try {
			const { data } = await accounts.createInvoice(
				accountId,
				signType,
				invoiceId,
				fd
			);
			return data.data;
		} catch (error) {
			console.error('Error', error);
			throw error;
		}
	};

	getPaymentDataById = async (accountId, paymentId) => {
		try {
			const { data } = await accounts.getPaymentDataById(accountId, paymentId);
			return data;
		} catch (error) {
			console.info('Error', error);
			throw error;
		}
	};

	acceptPayment = async (accountId, paymentId) => {
		try {
			const { data } = await accounts.acceptPaymentById(accountId, paymentId);
			return data;
		} catch (error) {
			console.info('Error', error);
			throw error;
		}
	};

	changePaymentSign = async (accountId, paymentId) => {
		try {
			const { data } = await accounts.changePaymentSign(accountId, paymentId);
			return data;
		} catch (error) {
			console.info('Error', error);
			throw error;
		}
	};

	changePaymentStatus = async (
		paymentId,
		qualityData,
		signatures,
		bank_data
	) => {
		try {
			const { data } = await accounts.changePaymentStatus(
				paymentId,
				qualityData,
				signatures,
				bank_data
			);
			return data;
		} catch (error) {
			console.error('Error', error);
			throw error;
		}
	};

	signPayment = async (accountId, paymentId, code = null, file = null) => {
		try {
			const { data } = await accounts.signPayment(
				accountId,
				paymentId,
				code,
				file
			);
			return data;
		} catch (error) {
			console.info('Error', error);
			throw error;
		}
	};

	aboutReportsDownloadData = (accountId, reportArchive) => {
		return accounts
			.aboutReportsDownloadData(accountId, reportArchive)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getReleaseShipments = (accountId, releaseId) => {
		return accounts
			.getReleaseShipments(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getReleaseTakedowns = (accountId, releaseId) => {
		return accounts
			.getReleaseTakedowns(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getReleaseTakedownedReleases = (accountId, releaseId) => {
		return accounts
			.getReleaseTakedownedReleases(accountId, releaseId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getShippingOutlets = (accountId, checkedReleases) => {
		return accounts
			.getShippingOutlets(accountId, checkedReleases)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	getTakedownsOutlets = (accountId, checkedReleases) => {
		return accounts
			.getTakedownsOutlets(accountId, checkedReleases)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.error('Error', error);
				throw error;
			});
	};

	postReleaseUpdatedShipments = (accountId, releaseId, outlets) => {
		return accounts
			.postReleaseUpdatedShipments(accountId, releaseId, outlets)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postReleaseUpdatedTakedowns = (accountId, releaseId, outlets) => {
		return accounts
			.postReleaseUpdatedTakedowns(accountId, releaseId, outlets)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	createAdminArticle = (file) => {
		return admin
			.createAdminArticle(file)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminArticle = (articleId) => {
		return admin
			.getAdminArticle(articleId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminArticlesBySlugLang = (lang, slug) => {
		return admin
			.getAdminArticlesBySlugLang(lang, slug)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	uploadAdminArticlePicture = (pic) => {
		return admin
			.uploadAdminArticlePicture(pic)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	updateAdminArticle = (articleId, file) => {
		return admin
			.updateAdminArticle(articleId, file)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteAdminArticle = (articleId) => {
		return admin
			.deleteAdminArticle(articleId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteAdminArticlePicFile = (fileId) => {
		return admin
			.deleteAdminArticlePicFile(fileId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getAdminReports = (page) => {
		return admin
			.getAdminReports(page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminReportsByFilter = (distributionId, page, filter, search) => {
		return admin
			.getAdminReportsByFilter(distributionId, page, filter, search)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	/* 	getAdminReportsByFilterWromModal = (distributionId, page, filter) => {
		return admin
			.getAdminReportsByFilterWromModal(distributionId, page, filter)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	}; */

	getAdminReportsNextDate = () => {
		return admin
			.getAdminReportsNextDate()
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postAdminReportsNextDate = (date) => {
		return admin
			.postAdminReportsNextDate(date)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	editAdminReportsNextDate = (id, date) => {
		return admin
			.editAdminReportsNextDate(id, date)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postReportArchiveRequest = (heading_en, heading, date_publication) => {
		return admin
			.postReportArchiveRequest(heading_en, heading, date_publication)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postReportXlsFile = (file) => {
		return admin
			.postReportXlsFile(file)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	editReportArchiveFile = (report_file_id, file) => {
		return admin
			.editReportArchiveFile(report_file_id, file)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	editManualReportData = (id, data) => {
		return admin
			.editManualReportData(id, data)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	editManualReportFile = (id, file) => {
		return admin
			.editManualReportFile(id, file)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	removeReport = (id) => {
		return admin
			.removeReport(id)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	startReportParsing = () => {
		return admin
			.startReportParsing()
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	editReportArchiveRequest = (report_file_id, date_publication) => {
		return admin
			.editReportArchiveRequest(report_file_id, date_publication)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	reloadReport = (identifier) => {
		return admin
			.reloadReport(identifier)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postReportArchiveFile = (file, reportId, config) => {
		return admin
			.postReportArchiveFile(file, reportId, config)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postReportArchiveFileS3 = (file_name_with_ext, file_size, reportId) => {
		return admin
			.postReportArchiveFileS3(file_name_with_ext, file_size, reportId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	putFileToS3 = (url, file, config) => {
		return admin
			.putFileToS3(url, file, config)
			.then((res) => {
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error_rootContext: ', error);
				return Promise.reject(error);
				// throw error;
			});
	};

	handleExportRelease = () => {
		return admin
			.handleExportRelease()
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	isAdminRedirectLocale = (id, isRedirectLocale) => {
		return admin
			.isAdminRedirectLocale(id, isRedirectLocale)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAllAdminReports = () => {
		return admin
			.getAllAdminReports()
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error_rootContext: ', error);
				return Promise.reject(error);
				// throw error;
			});
	};
	getAdminReportsDistributions = (page, search) => {
		return admin
			.getAdminReportsDistributions(page, search)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error_rootContext: ', error);

				throw error;
			});
	};
	createAdminReportsDistributions = (data, date_publication) => {
		return admin
			.createAdminReportsDistributions(data, date_publication)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error_rootContext: ', error);

				throw error;
			});
	};

	getContractConditionDetail = (contractId, conditionId) => {
		return contracts
			.getContractConditionDetail(contractId, conditionId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	//=== admin distributions ====
	getAdminDistributions = (page) => {
		return admin
			.getAdminDistributions(page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAllAdminDistributions = () => {
		return admin
			.getAllAdminDistributions()
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminDistributionNextDate = (distributionId) => {
		return admin
			.getAdminDistributionNextDate(distributionId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	putAdminDistributionNextDate = (distributionId, date) => {
		return admin
			.putAdminDistributionNextDate(distributionId, date)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getAdminDistributionReports = (distributionId, page) => {
		return admin
			.getAdminDistributionReports(distributionId, page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getAllAdminDistributionReports = (distributionId) => {
		return admin
			.getAllAdminDistributionReports(distributionId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getAdminDistributionReportsByFilter = (distributionId, page, filter) => {
		return admin
			.getAdminDistributionReportsByFilter(distributionId, page, filter)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	removeAdminReportsDistributions = (distributionId) => {
		return admin
			.removeAdminReportsDistributions(distributionId)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	putAdminDistributionReportArchive = (distributionId, data) => {
		return admin
			.putAdminDistributionReportArchive(distributionId, data)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postAdminDistributionReportFile = (distributionId, data) => {
		return admin
			.postAdminDistributionReportFile(distributionId, data)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	putAdminDistributionEditContentRequest = (
		distributionId,
		report_files_id,
		date_publication,
		data
	) => {
		return admin
			.putAdminDistributionEditContentRequest(
				distributionId,
				report_files_id,
				date_publication,
				data
			)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postAdminRubricsList = (rubricsIDs) => {
		return admin
			.postAdminRubricsList(rubricsIDs)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getCompositionData = (compositionId) => {
		return accounts
			.getCompositionData(compositionId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getRepertoireDataByCiType = (id, any, ciType, page, text) => {
		return accounts
			.getRepertoireDataByCiType(id, any, ciType, page, text)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	getAvailableServices = (id) => {
		return accounts
			.getAvailableServices(id)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	postAdditionalServices = (id, data) => {
		return accounts
			.postAdditionalServices(id, data)
			.then((res) => {
				res = res.data.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	getUnprocessedDrafts = (page) => {
		return admin
			.getUnprocessedDrafts(page)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteDraft = (draftType, draftId) => {
		return accounts
			.deleteDraft(draftType, draftId)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	deleteDraftsList = (ids) => {
		return accounts
			.deleteDraftsList(ids)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};

	postAccountSocieties = (id, data) => {
		return accounts
			.postAccountSocieties(id, data)
			.then((res) => {
				res = res.data;
				return Promise.resolve(res);
			})
			.catch((error) => {
				console.info('Error', error);
				throw error;
			});
	};
	//============================

	//===================================================

	componentDidMount() {
		this.getContractPeriods();
		this.getCurrencies();
		this.update();
	}

	update = () => {
		this.getCountries();
		this.getTerritories();
		this.getTerritoriesWithCompound();
	};

	render() {
		return (
			<RootContext.Provider
				value={{
					...this.state,
					setReportStatus: this.setReportStatus,
					setIsReportDownloaded: this.setIsReportDownloaded,
					update: this.update,
					createAccount: this.createAccount,
					getRoles: this.getRoles,
					getRolesForAddNew: this.getRolesForAddNew,
					getSharedRoles: this.getSharedRoles,
					getSelfRoles: this.getSelfRoles,
					getReportsPersonalInfo: this.getReportsPersonalInfo,
					updateReportsPersonalInfo: this.updateReportsPersonalInfo,
					getAccountPersonalData: this.getAccountPersonalData,
					updateAccountPersonalData: this.updateAccountPersonalData,
					updateAccountCompanyData: this.updateAccountCompanyData,
					getAccountContactsData: this.getAccountContactsData,
					updateAccountContactsData: this.updateAccountContactsData,
					suggestSociety: this.suggestSociety,
					uploadAccountDocument: this.uploadAccountDocument,
					deleteAccountDocument: this.deleteAccountDocument,
					acceptAccountDocuments: this.acceptAccountDocuments,
					getAccountDocuments: this.getAccountDocuments,
					getAccountPaymentData: this.getAccountPaymentData,
					updateAccountPaymentData: this.updateAccountPaymentData,
					sendAccountToModeration: this.sendAccountToModeration,
					getAccountPseudonyms: this.getAccountPseudonyms,
					createAccountPseudonyms: this.createAccountPseudonyms,
					deleteAccountPseudonym: this.deleteAccountPseudonym,
					deleteAccountSociety: this.deleteAccountSociety,
					getContractData: this.getContractData,
					updateContractData: contracts.updateContractData,
					submitContractData: contracts.submitContractData,
					getContracts: contracts.getContracts,
					getAdditionalContracts: contracts.getAdditionalContracts,
					acceptContract: contracts.acceptContract,
					uploadContract: contracts.uploadContract,
					deleteUploadedContract: contracts.deleteUploadedContract,
					resendSignSMS: contracts.resendSignSMS,
					signContract: contracts.signContract,
					submitContracts: contracts.submitContracts,
					noCodeSignSMS: contracts.noCodeSignSMS,
					completeSigning: contracts.completeSigning,
					addRoles: accounts.addRoles,
					addAdditionalRoles: accounts.addAdditionalRoles,
					getContractsTerritories: this.getContractsTerritories,
					updateContractsTerritories: this.updateContractsTerritories,
					getCountryById: this.getCountryById,
					getCountryDialingCode: this.getCountryDialingCode,
					getDocumentTitle: this.getDocumentTitle,
					uploadRepertoire: accounts.uploadRepertoire,
					saveDocsFiles: this.saveDocsFiles,
					saveReportFiles: this.saveReportFiles,
					handleDashboardScrolled: this.handleDashboardScrolled,
					getTerritories: this.getTerritories,
					getTerritoriesWithCompound: this.getTerritoriesWithCompound,
					getAccountStatisticOutlets: this.getAccountStatisticOutlets,
					getAccountStatisticAuthorOutlets: this
						.getAccountStatisticAuthorOutlets,
					getAccountStatisticsCountries: this.getAccountStatisticsCountries,
					getAccountStatisticsAuthorCountries: this
						.getAccountStatisticsAuthorCountries,
					getAccountStatisticCheck: this.getAccountStatisticCheck,
					getReleaseStatisticCheck: this.getReleaseStatisticCheck,
					getAccountStatisticTopReleases: this.getAccountStatisticTopReleases,
					getAccountStatisticTopReleasesInfo: this
						.getAccountStatisticTopReleasesInfo,
					getAccountStatisticTopTracks: this.getAccountStatisticTopTracks,
					getAccountStatisticAuthorTopTracks: this
						.getAccountStatisticAuthorTopTracks,
					getAccountStatisticAllTracks: this.getAccountStatisticAllTracks,
					getAccountStatisticAuthorAllTracks: this
						.getAccountStatisticAuthorAllTracks,
					getAccountStatisticStreams: this.getAccountStatisticStreams,
					getAccountStatisticAuthorStreams: this
						.getAccountStatisticAuthorStreams,
					getAccountStatisticStreamsV2: this.getAccountStatisticStreamsV2,
					getAccountStatisticMap: this.getAccountStatisticMap,
					getAccountStatisticMapByDate: this.getAccountStatisticMapByDate,
					getAccountStatisticAuthorMapByDate: this
						.getAccountStatisticAuthorMapByDate,
					getAccountStatisticsColumnar: this.getAccountStatisticsColumnar,
					getAccountStatisticsAuthorColumnar: this
						.getAccountStatisticsAuthorColumnar,
					switchToPhysical: this.switchToPhysical,
					setAccountStep: this.setAccountStep,
					getMvpUserData: this.getMvpUserData,
					getAdminSteps: this.getAdminSteps,
					getAccountContracts: this.getAccountContracts,
					getContractCondition: this.getContractCondition,
					getContractAttachments: this.getContractAttachments,
					getContractById: this.getContractById,
					getRecordingMetadata: this.getRecordingMetadata,
					getRecordingCover: this.getRecordingCover,
					getRecordingAudio: this.getRecordingAudio,
					getRecordingIssues: this.getRecordingIssues,
					getRecordingReleases: this.getRecordingReleases,
					getRecordingOwnership: this.getRecordingOwnership,
					getRecordingOutlets: this.getRecordingOutlets,
					getRecordingProducts: this.getRecordingProducts,

					getExploitationTypes: this.getExploitationTypes,
					getRecordingTypes: this.getRecordingTypes,
					getCommercialModels: this.getCommercialModels,
					getGenres: this.getGenres,
					getReleaseTypes: this.getReleaseTypes,
					getReleaseTypesAll: this.getReleaseTypesAll,

					getCompositionMetadata: this.getCompositionMetadata,
					getCompositionOutlets: this.getCompositionOutlets,
					getCompositionProducts: this.getCompositionProducts,
					getCompositionOwnership: this.getCompositionOwnership,
					getCompositionTypes: this.getCompositionTypes,
					getCompositionRecordings: this.getCompositionRecordings,
					getCompositionReleases: this.getCompositionReleases,
					getCompositionIssues: this.getCompositionIssues,
					getLanguages: this.getLanguages,
					getRecordingsOriginRightholders: this.getRecordingsOriginRightholders,
					getCompositionsOriginRightholders: this
						.getCompositionsOriginRightholders,
					getContributorRoles: this.getContributorRoles,
					getContributorRolesByAccId: this.getContributorRolesByAccId,
					getRecordingsRevenue: this.getRecordingsRevenue,
					getCompositionsRevenue: this.getCompositionsRevenue,
					getCountries: this.getCountries,
					getFaqList: this.getFaqList,
					getFaqSearchByText: this.getFaqSearchByText,
					getFaqSearchBySectionId: this.getFaqSearchBySectionId,
					getFaqSearchByArticleId: this.getFaqSearchByArticleId,

					getSignupsUserData: this.getSignupsUserData,
					deleteSignupsUserData: this.deleteSignupsUserData,
					deleteNotApprovedAcc: this.deleteNotApprovedAcc,
					signupEmailVerification: this.signupEmailVerification,
					signupSmsVerification: this.signupSmsVerification,
					getSignupsSteps: this.getSignupsSteps,

					getAdminStatisticService: this.getAdminStatisticService,
					getAccountStatisticReports: this.getAccountStatisticReports,
					createStatisticAccountReport: this.createStatisticAccountReport,
					createStatisticAccountSummaryReport: this
						.createStatisticAccountSummaryReport,
					getRespStatisticAccountReports: this.getRespStatisticAccountReports,
					getStatisticAccountReportFile: this.getStatisticAccountReportFile,
					createStatisticAdminReport: this.createStatisticAdminReport,
					getRespStatisticAdminReports: this.getRespStatisticAdminReports,
					getStatisticAdminReportFile: this.getStatisticAdminReportFile,
					getAdminStatisticReports: this.getAdminStatisticReports,
					getAdminNotifications: this.getAdminNotifications,
					createAdminNotifications: this.createAdminNotifications,
					getUsersAdminV2: this.getUsersAdminV2,
					deleteUsersAdmin: this.deleteUsersAdmin,
					getUserNotifications: this.getUserNotifications,
					putReadUserNotification: this.putReadUserNotification,
					getUserNotificationsSettings: this.getUserNotificationsSettings,
					putUserNotificationsSettings: this.putUserNotificationsSettings,
					getRecordingsCompositions: this.getRecordingsCompositions,
					getAdminArticles: this.getAdminArticles,
					getAdminArticlesRubrics: this.getAdminArticlesRubrics,
					getAdminArticlesSectionsAll: this.getAdminArticlesSectionsAll,
					addAdminArticlesRubric: this.addAdminArticlesRubric,
					updateAdminArticlesRubric: this.updateAdminArticlesRubric,
					createAdminArticle: this.createAdminArticle,
					getAdminArticlesSectionsTranslate: this
						.getAdminArticlesSectionsTranslate,
					getAdminArticle: this.getAdminArticle,
					getAdminArticlesBySlugLang: this.getAdminArticlesBySlugLang,
					uploadAdminArticlePicture: this.uploadAdminArticlePicture,
					updateAdminArticle: this.updateAdminArticle,
					deleteAdminArticle: this.deleteAdminArticle,
					deleteAdminArticlePicFile: this.deleteAdminArticlePicFile,
					getAdminReports: this.getAdminReports,
					getAdminReportsByFilter: this.getAdminReportsByFilter,
					getAdminReportsNextDate: this.getAdminReportsNextDate,
					postAdminReportsNextDate: this.postAdminReportsNextDate,
					editAdminReportsNextDate: this.editAdminReportsNextDate,
					postReportArchiveRequest: this.postReportArchiveRequest,
					getAccountReports: this.getAccountReports,
					getAccountPaymentsInfo: this.getAccountPaymentsInfo,
					getAccountPaymentsReports: this.getAccountPaymentsReports,
					getAccountPaymentsReportById: this.getAccountPaymentsReportById,
					getAccountPayments: this.getAccountPayments,
					getPaymentInvoice: this.getPaymentInvoice,
					createInvoice: this.createInvoice,
					getPaymentDataById: this.getPaymentDataById,
					acceptPayment: this.acceptPayment,
					changePaymentSign: this.changePaymentSign,
					changePaymentStatus: this.changePaymentStatus,
					signPayment: this.signPayment,
					aboutReportsDownloadData: this.aboutReportsDownloadData,
					editReportArchiveFile: this.editReportArchiveFile,
					editReportArchiveRequest: this.editReportArchiveRequest,
					removeReport: this.removeReport,
					reloadReport: this.reloadReport,

					postReportArchiveFile: this.postReportArchiveFile,
					postReportArchiveFileS3: this.postReportArchiveFileS3,
					postReportXlsFile: this.postReportXlsFile,
					putFileToS3: this.putFileToS3,
					getAllAdminReports: this.getAllAdminReports,

					searchArtists: this.searchArtists,
					getArtist: this.getArtist,
					createArtist: this.createArtist,
					updateArtist: this.updateArtist,

					searchAuthorSocieties: this.searchAuthorSocieties,
					getAuthorSocieties: this.getAuthorSocieties,
					createAuthorSociety: this.createAuthorSociety,
					updateAuthorSociety: this.updateAuthorSociety,
					deleteAuthorSociety: this.deleteAuthorSociety,

					getCompositionAuthorSocieties: this.getCompositionAuthorSocieties,
					createCompositionAuthorSociety: this.createCompositionAuthorSociety,
					updateCompositionAuthorSociety: this.updateCompositionAuthorSociety,
					deleteCompositionAuthorSociety: this.deleteCompositionAuthorSociety,

					getAccountPseudonymsData: this.getAccountPseudonymsData,
					updatePseudonym: this.updatePseudonym,
					deletePseudonym: this.deletePseudonym,
					createPseudonym: this.createPseudonym,
					getOnePseudonymData: this.getOnePseudonymData,
					getContractConditionDetail: this.getContractConditionDetail,

					editManualReportFile: this.editManualReportFile,
					editManualReportData: this.editManualReportData,
					startReportParsing: this.startReportParsing,
					handleExportRelease: this.handleExportRelease,

					getAdminDistributions: this.getAdminDistributions,
					getAllAdminDistributions: this.getAllAdminDistributions,
					getAdminDistributionNextDate: this.getAdminDistributionNextDate,
					putAdminDistributionNextDate: this.putAdminDistributionNextDate,
					getAdminDistributionReports: this.getAdminDistributionReports,
					getAllAdminDistributionReports: this.getAllAdminDistributionReports,
					getAdminDistributionReportsByFilter: this
						.getAdminDistributionReportsByFilter,

					getAdminReportsDistributions: this.getAdminReportsDistributions,
					createAdminReportsDistributions: this.createAdminReportsDistributions,
					removeAdminReportsDistributions: this.removeAdminReportsDistributions,
					putAdminDistributionReportArchive: this
						.putAdminDistributionReportArchive,
					postAdminDistributionReportFile: this.postAdminDistributionReportFile,
					putAdminDistributionEditContentRequest: this
						.putAdminDistributionEditContentRequest,
					isAdminRedirectLocale: this.isAdminRedirectLocale,
					getReleaseShipments: this.getReleaseShipments,
					getReleaseTakedowns: this.getReleaseTakedowns,
					getReleaseTakedownedReleases: this.getReleaseTakedownedReleases,
					getShippingOutlets: this.getShippingOutlets,
					getTakedownsOutlets: this.getTakedownsOutlets,
					postReleaseUpdatedShipments: this.postReleaseUpdatedShipments,
					postReleaseUpdatedTakedowns: this.postReleaseUpdatedTakedowns,
					deleteAdminArticlesRubric: this.deleteAdminArticlesRubric,
					postAdminRubricsList: this.postAdminRubricsList,
					getContractSignTypes: this.getContractSignTypes,
					getCompositionData: this.getCompositionData,
					getRepertoireDataByCiType: this.getRepertoireDataByCiType,
					getAvailableServices: this.getAvailableServices,
					postAdditionalServices: this.postAdditionalServices,
					getUnprocessedDrafts: this.getUnprocessedDrafts,
					deleteDraft: this.deleteDraft,
					deleteDraftsList: this.deleteDraftsList,
					postAccountSocieties: this.postAccountSocieties,
					setAllowCreateAdditionalAccountsFlag: this
						.setAllowCreateAdditionalAccountsFlag,
					deleteAllowCreateAdditionalAccountsFlag: this
						.deleteAllowCreateAdditionalAccountsFlag,
					getFlagsWithId: this.getFlagsWithId,
					addFlag: this.addFlag,
					removeFlag: this.removeFlag,

					//Filters
					getAccountStatisticAllPerformers: this
						.getAccountStatisticAllPerformers,
					getAccountStatisticAuthorAllPerformers: this
						.getAccountStatisticAuthorAllPerformers,
					getAccountStatisticAllRecordings: this
						.getAccountStatisticAllRecordings,
					getAccountStatisticAuthorAllRecordings: this
						.getAccountStatisticAuthorAllRecordings,
					getAccountStatisticAllReleases: this.getAccountStatisticAllReleases,
					getAccountRecordingsInPlaylists: this.getAccountRecordingsInPlaylists,
					getAccountRecordingPageInPlaylists: this
						.getAccountRecordingPageInPlaylists,
					getAccountRecordingsFilterPlaylist: this
						.getAccountRecordingsFilterPlaylist,
					getAccountCountriesFilterPlaylist: this
						.getAccountCountriesFilterPlaylist,
				}}
			>
				{this.props.children}
			</RootContext.Provider>
		);
	}
}

const RootConsumer = RootContext.Consumer;

RootProvider = (withLang, withAuth)(RootProvider);

export { RootConsumer, RootContext, RootProvider };
