import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

// UI
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { Loading } from 'components';

// Contexts
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Styles
import styles from './PrivilegeModal.module.css';

const PrivilegeModal = ({ active, setActive, type, account }) => {
	const [isLoading, setIsLoading] = useState(false);
	const privilegeData = useRef({});

	const [localData, setLocalData] = useState({});

	const {
		isBlockArtistIdFeature,
		isEditingReleasesAfterShipment,
		isShipmentsTakedownsEnableFeature,
	} = useContext(UIContext);
	
	const { lang } = useContext(LangContext);
	const { getFlagsWithId, addFlag, removeFlag } = useContext(RootContext);

	useEffect(() => {
		setIsLoading(true);
		let data;
		const fetchData = async () => {
			if (type === 'user') {
				data = await getFlagsWithId(account.user_data.id, null);
			} else {
				data = await getFlagsWithId(null, account.personal_data.account_id);
			}
			// Don't ask me, idk why it works this way
			privilegeData.current = JSON.parse(JSON.stringify(data));
			setLocalData(data);
			setIsLoading(false);
		};
		fetchData();
	}, [active, type]);

	const handleChecked = (val) => {
		const changedField = {
			...localData,
			[type]: localData[type].map((privilegeItem) => {
				if (privilegeItem.id === val.id) {
					privilegeItem.announced = !val.announced;
				}
				return privilegeItem;
			}),
		};
		setLocalData(changedField);
	};

	useEffect(() => {
		return () => {
			setLocalData([]);
		};
	}, []);

	const onSubmit = async () => {
		const isUser = type === 'user';
		setIsLoading(true);

		await Promise.all(
			localData[type].map(async (localPrivilege) => {
				const existItem = privilegeData.current[type].find(
					(privilege) => privilege.id === localPrivilege.id
				);
				if (existItem && existItem.announced !== localPrivilege.announced) {
					switch (localPrivilege.announced) {
						case true: {
							await addFlag(
								isUser
									? account.user_data.id
									: account.personal_data.account_id,
								isUser ? localPrivilege.code : localPrivilege.id,
								type
							);
							break;
						}
						case false: {
							await removeFlag(
								isUser
									? account.user_data.id
									: account.personal_data.account_id,
								isUser ? localPrivilege.code : localPrivilege.id,
								type
							);
							break;
						}
						default: {
							return;
						}
					}
				}
			})
		);
		setIsLoading(false);
		setActive(false);
	};

	const isDisabledCheckbox = (code) => {
		if (
			(isEditingReleasesAfterShipment &&
				code === 'account_feature.editing_release') ||
			(isShipmentsTakedownsEnableFeature &&
				code === 'account_feature.interaction_shipped_release') ||
			(!isBlockArtistIdFeature && code === 'account_feature.artist_id')
		) {
			return true;
		}
		return false;
	};

	return (
		<>
			<div
				className={
					active ? `${styles.Overlay} ${styles.OverlayActive}` : styles.Overlay
				}
			/>
			<div
				className={active ? `${styles.modal} ${styles.active}` : styles.modal}
				onClick={() => setActive(false)}
			>
				<div className={styles.container} onClick={(e) => e.stopPropagation()}>
					<div className={styles.modalHeader}>
						<div className={styles.modalHeader__titleWrapper}>
							<span className={styles.modalHeader__title}>
								<FormattedMessage
									id={`rod.admin.account.privilege.title-${type}`}
								/>
							</span>

							<div className={styles.modalHeader__icon}>
								<CloseIcon
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					<div className={styles.modalBody}>
						{isLoading ? (
							<Loading className={styles.loader} />
						) : (
							<>
								{localData?.[type]?.length ? (
									localData?.[type]?.map((privilegeItem) => {
										return (
											<Checkbox
												key={privilegeItem.id}
												text={privilegeItem[lang.toUpperCase()]}
												checked={privilegeItem.announced}
												onChange={() => handleChecked(privilegeItem)}
												value={privilegeItem}
												className={styles.checkbox}
												disabled={isDisabledCheckbox(privilegeItem.code)}
											/>
										);
									})
								) : (
									<div className={styles.placeholder}>
										<FormattedHTMLMessage
											id={'rod.admin.account.privilege-empty'}
										/>
									</div>
								)}
							</>
						)}
						<Button
							className={styles.button__select}
							variant={'primary'}
							text={<FormattedHTMLMessage id={'rod.release.info.apply'} />}
							onClick={onSubmit}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivilegeModal;
