// Core
import axios from 'axios';
import { useContext } from 'react';

// Context
import { UIContext } from 'contexts/UIContext';

const url = process.env.REACT_APP_API_URL;
const api = axios.create({
	withCredentials: true,
	baseURL: `${url}`,
});

export const AxiosInterceptor = ({ children }) => {
	const { showErrorInterceptor } = useContext(UIContext);
	let isSessionExpiredRedirecting = false;

	api.interceptors.request.use((config) => {
		if (config.url.includes('amazonaws')) {
			config.headers = {
				'Content-Type': 'application/zip',
			};
		} else if (config.url === '/auth/token/create') {
			config.headers = {
				'Content-Language': localStorage.getItem('lang') || 'en',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Access-Token': localStorage.getItem('token') || null,
				'X-Client-Version': window.COMMIT_SHA || null,
				'Content-Type': 'application/json',
				'Refresh-Token': localStorage.getItem('refresh') || null,
			};
		} else {
			config.headers = {
				'Content-Language': localStorage.getItem('lang') || 'en',
				'X-Requested-With': 'XMLHttpRequest',
				'X-Access-Token': localStorage.getItem('token') || null,
				'X-Refresh-Token': localStorage.getItem('refresh') || null,
				'X-Client-Version': window.COMMIT_SHA || null,
				'Content-Type': 'application/json',
			};
		}
		return config;
	});

	api.interceptors.response.use(
		(config) => {
			return config;
		},
		async (error) => {
			const originalRequest = error.config;
			const refreshToken = localStorage.getItem('refresh');
			const { status } = error.response || {};

			if (
				status === 502 &&
				!window.location.pathname.includes('/service-not-available')
			) {
				window.location = '/service-not-available';
				return Promise.reject(error);
			}

			if (status === 401 && !refreshToken) {
				localStorage.clear();
				window.location = '/login';
				return Promise.reject(error);
			}

			if (
				status === 401 &&
				refreshToken &&
				error?.config &&
				!error?.config?.secondTime
			) {
				originalRequest.secondTime = true;
				try {
					const refreshToken = localStorage.getItem('refresh');
					const headers = {
						'X-Access-Token': localStorage.getItem('token'),
					};
					const response = await axios.post(
						`${url}/auth/refresh`,
						{
							withCredentials: true,
							refresh_token: refreshToken,
						},
						{
							headers: headers,
						}
					);
					localStorage.setItem('token', response.data.access_token);
					localStorage.setItem('refresh', response.data.refresh_token);
					return api.request(originalRequest);
				} catch (err) {
					return handleRefreshTokenError(err, error);
				}
			} else {
				showErrorInterceptor(error);
			}

			if (error === 'Network Error') {
				return Promise.reject(error);
			}

			if (error?.message === 'Network Error') {
				return Promise.reject(error.message);
			}

			if (status === 413) {
				return Promise.reject(error);
			}

			// Handle timeout error
			if (status === 504 || error.response?.data?.errors === 'timed out') {
				// Check if the request has already been retried
				if (!originalRequest._retry) {
					// Set a flag to indicate the retry
					originalRequest._retry = true;
					try {
						// Retry the original request
						const response = await api.request(originalRequest);
						return response;
					} catch (retryError) {
						// Handle retry error
						return Promise.reject(retryError);
					}
				}
			}
		}
	);

	const handleRefreshTokenError = (err, error) => {
		if (error.config.url.includes('logout')) {
			localStorage.clear();
			window.location = '/login';
			return Promise.reject(err);
		}

		if (err.response && err.response.status === 401) {
			if (!isSessionExpiredRedirecting) {
				isSessionExpiredRedirecting = true;
				localStorage.clear();
				window.location = '/session-expired';
			}
			return Promise.reject(err);
		}

		return showErrorInterceptor(err);
	};

	return children;
};

export default api;
